export default theme => ({
  container: {},
  number: {
    fontFamily: 'Archia',
    fontWeight: 900,
    whiteSpace: 'nowrap',
  },
  medium: {
    fontSize: '4.5rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2rem',
    },
  },
  small: {
    fontSize: '3.5rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.4rem',
    },
  },
  blue: {
    color: theme.palette.blue.main,
  },
  orange: {
    color: theme.palette.orange.main,
  },
  green: {
    color: theme.palette.green.main,
  },
  yellow: {
    color: theme.palette.yellow.dark,
  },
  red: {
    color: theme.palette.red.main,
  },
  white: {
    color: theme.palette.white.main,
  },
  black: {
    color: theme.palette.black.main,
  },
});
