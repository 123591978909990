import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

import { ReactComponent as Arrow } from '../../res/arrowDiagonal.svg';
import Eclat from '../../components/Eclat';
import parse from '../../utils/Parser';

import styles from './styles';

class Interview extends React.PureComponent {
  render() {
    const { classes, open, onClose, data } = this.props;
    return (
      <Dialog
        fullWidth
        maxWidth="lg"
        open={open}
        onClose={onClose}
        PaperProps={{ square: true, className: classes.paper }}
      >
        <Container maxWidth="lg" className={classes.container}>
          <IconButton onClick={onClose} className={classes.closeIcon}>
            <CloseIcon fontSize="large" />
          </IconButton>
          {this.renderInner()}
          <Typography className={classes.title}>{parse(data.interview.title)}</Typography>
        </Container>
      </Dialog>
    );
  }

  renderInner() {
    const { data, classes } = this.props;
    return (
      <div className={classes.inner}>
        <Eclat color="white" variant="quote" rotation={190} className={classes.eclat} />
        <Arrow className={classes.arrow} />
        <Typography variant="h4" className={classes.author}>
          {parse(data.interview.author)}
        </Typography>
        <Typography variant="body1" className={classes.role}>
          {parse(data.interview.role)}
        </Typography>
        <Typography variant="body2" className={classes.content}>
          {parse(data.interview.content)}
        </Typography>
      </div>
    );
  }
}

Interview.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default withStyles(styles)(Interview);
