export default theme => ({
  blockContainer: {
    position: 'relative',
  },
  container: {
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  card: {
    margin: theme.spacing(8, 0, 8, 0),
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(2, 0, 2, 0),
    },
  },
  subtitle: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    padding: theme.spacing(2, 0, 2, 0),
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
    },
  },
  quoteBackground: {
    backgroundColor: theme.palette.secondary.main,
  },
  quoteBlock: {
    marginBottom: theme.spacing(8),
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  eclatQuoteTop: {
    position: 'absolute',
    width: 150,
    top: -theme.spacing(1),
    right: 0,
    [theme.breakpoints.down('sm')]: {
      width: 75,
      top: 'initial',
      right: 'initial',
      bottom: -theme.spacing(2),
      left: theme.spacing(2),
    },
  },
  eclatQuote: {
    position: 'absolute',
    bottom: theme.spacing(2),
    width: 110,
    right: 'calc(40% - 110px)',
    [theme.breakpoints.down('sm')]: {
      width: 60,
      bottom: theme.spacing(-4),
    },
  },
  imgWrapper: {
    position: 'relative',
    flex: 1,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(2),
    },
  },
  imgRound: {
    position: 'relative',
    width: 400,
    height: 400,
    borderRadius: 500,
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      width: 250,
      height: 250,
    },
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center bottom',
  },
  quoteWrapper: {
    flex: '2 1 0',
    position: 'relative',
    padding: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
    },
  },
  quote: {
    paddingBottom: theme.spacing(4),
  },
  autor: {
    paddingBottom: theme.spacing(1),
    color: theme.palette.orange.main,
  },
  role: {
    paddingBottom: theme.spacing(2),
    color: theme.palette.orange.main,
  },
  podcastsWrapper: {
    display: 'grid',
    gridTemplateColumns: '2fr 1fr 1fr',
    gridTemplateRows: '1fr 1fr',
    margin: theme.spacing(-1, -1, -1, -1),
    marginBottom: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr 1fr',
      gridTemplateRows: '2fr 1fr 1fr',
      marginBottom: theme.spacing(4),
    },
  },
  podcast: {
    position: 'relative',
    backgroundColor: theme.palette.orange.main,
    color: theme.palette.secondary.main,
    minHeight: 300,
    textAlign: 'left',
    background: `no-repeat url(${theme.url('/blocks/b2-zootopique.png')})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    margin: theme.spacing(1, 1, 1, 1),
    transition: 'color 0.3s, fill 0.3s, stroke 0.3s',
    [theme.breakpoints.down('xs')]: {
      minHeight: 200,
    },
    '&:hover .iconPodcast': {
      stroke: theme.palette.orange.main,
      fill: theme.palette.orange.main,
    },
  },
  one: {
    gridColumn: '1 / 2',
    gridRow: '1 / 3',
    [theme.breakpoints.down('sm')]: {
      gridColumn: '1 / 3',
      gridRow: '1 / 2',
    },
    '& .iconPodcast': {
      [theme.breakpoints.down('xs')]: {
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 80,
        height: 80,
      },
    },
  },
  two: {
    gridColumn: 2,
    gridRow: 1,
    [theme.breakpoints.down('sm')]: {
      gridColumn: 1,
      gridRow: 2,
    },
  },
  three: {
    gridColumn: 3,
    gridRow: 1,
    [theme.breakpoints.down('sm')]: {
      gridColumn: 2,
      gridRow: 2,
    },
  },
  four: {
    gridColumn: 2,
    gridRow: 2,
    [theme.breakpoints.down('sm')]: {
      gridColumn: 1,
      gridRow: 3,
    },
  },
  five: {
    gridColumn: 3,
    gridRow: 2,
    [theme.breakpoints.down('sm')]: {
      gridColumn: 2,
      gridRow: 3,
    },
  },
  centeredButton: {
    textAlign: 'center',
  },
  button: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.8rem',
    },
  },
  iconPodcast: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    stroke: theme.palette.white.main,
    fill: theme.palette.white.main,
    transition: 'fill 0.3s, stroke 0.3s',
    [theme.breakpoints.down('xs')]: {
      top: '10%',
      left: '50%',
      transform: 'translate(-50%, 0%)',
      width: 80,
      height: 80,
    },
  },
  textPodcast: {
    position: 'absolute',
    bottom: '5%',
    left: '5%',
  },
  descTitle: {
    marginBottom: theme.spacing(2),
  },
});
