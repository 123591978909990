export default theme => ({
  container: {
    position: 'relative',
    width: '100%',
    textAlign: 'center',
    overflow: 'hidden',
    paddingBottom: theme.spacing(8),
    paddingTop: theme.spacing(8),
    marginBottom: theme.spacing(12),
    marginTop: theme.spacing(12),
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(4),
      paddingTop: theme.spacing(4),
      marginBottom: theme.spacing(6),
      marginTop: theme.spacing(6),
    },
  },
  eclatContainer: {
    position: 'absolute',
    height: '100%',
    top: 0,
    left: 16,
    pointerEvents: 'none',
    width: 'calc(100% - 32px)',
    '@media (min-width:600px)': {
      width: 'calc(100% - 48px)',
      left: 24,
    },
    '@media (min-width:960px)': {
      width: 'calc(100% - 128px)',
      left: 64,
    },
    '@media (min-width:1024px)': {
      width: 'calc(100% - 200px)',
      left: 100,
    },
  },
  blue: {
    backgroundColor: theme.palette.blue.main,
    textShadow: `-1px 0 ${theme.palette.blue.main}, 0 1px ${theme.palette.blue.main}, 1px 0 ${theme.palette.blue.main}, 0 -1px ${theme.palette.blue.main}`,
  },
  orange: {
    backgroundColor: theme.palette.orange.main,
    textShadow: `-1px 0 ${theme.palette.orange.main}, 0 1px ${theme.palette.orange.main}, 1px 0 ${theme.palette.orange.main}, 0 -1px ${theme.palette.orange.main}`,
  },
  green: {
    backgroundColor: theme.palette.green.main,
    textShadow: `-1px 0 ${theme.palette.green.main}, 0 1px ${theme.palette.green.main}, 1px 0 ${theme.palette.green.main}, 0 -1px ${theme.palette.green.main}`,
  },
  yellow: {
    backgroundColor: theme.palette.yellow.main,
    textShadow: `-1px 0 ${theme.palette.yellow.main}, 0 1px ${theme.palette.yellow.main}, 1px 0 ${theme.palette.yellow.main}, 0 -1px ${theme.palette.yellow.main}`,
  },
  red: {
    backgroundColor: theme.palette.red.main,
    textShadow: `-1px 0 ${theme.palette.red.main}, 0 1px ${theme.palette.red.main}, 1px 0 ${theme.palette.red.main}, 0 -1px ${theme.palette.red.main}`,
  },
  white: {
    fill: theme.palette.white.main,
    textShadow: `-1px 0 ${theme.palette.white.main}, 0 1px ${theme.palette.white.main}, 1px 0 ${theme.palette.white.main}, 0 -1px ${theme.palette.white.main}`,
  },
  black: {
    backgroundColor: theme.palette.black.main,
    textShadow: `-1px 0 ${theme.palette.black.main}, 0 1px ${theme.palette.black.main}, 1px 0 ${theme.palette.black.main}, 0 -1px ${theme.palette.black.main}`,
  },
  eclatTop: {
    position: 'absolute',
    right: -25,
    top: -120,
    [theme.breakpoints.down('sm')]: {
      top: -90,
      width: 75,
      right: 0,
    },
    [theme.breakpoints.down('xs')]: {
      top: -70,
      width: 50,
      right: 0,
    },
  },
  eclatBottom: {
    position: 'absolute',
    left: 0,
    bottom: -150,
    [theme.breakpoints.down('sm')]: {
      width: 75,
      left: 25,
      bottom: -115,
    },
    [theme.breakpoints.down('xs')]: {
      width: 50,
      left: 25,
      bottom: -90,
    },
  },
  title: {
    position: 'relative',
    zIndex: 2,
    display: 'inline-block',
    textAlign: 'left',
    '&:after': {
      content: '""',
      display: 'inline-block',
      position: 'relative',
      left: theme.spacing(2),
      height: 47,
      width: 47,
      backgroundSize: '47px 47px',
      backgroundImage: `url(${theme.url('/images/arrowDiagonal.svg')})`,
      backgroundRepeat: 'no-repeat',
      [theme.breakpoints.down('md')]: {
        height: 30,
        width: 30,
        backgroundSize: '30px 30px',
      },
      [theme.breakpoints.down('xs')]: {
        left: theme.spacing(1),
        height: 20,
        width: 20,
        backgroundSize: '20px 20px',
      },
    },
  },
});
