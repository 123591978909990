import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import styles from './styles';

class Eclat extends React.PureComponent {
  renderQuote() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 155.5 155.2">
        <path d="M3.1,45.8c-13,34.9,18.8,75.9,21.3,79c18.6-14.6,32.8-34,41-56.2C73.5,46.7,75.5,23,71,0C68,0.6,16.3,10.6,3.1,45.8" />
        <path d="M85.3,76.2c-13,34.9,18.8,75.9,21.3,79c18.6-14.6,32.8-34,41-56.2c8.2-21.9,10.1-45.6,5.7-68.6C150.1,31,98.4,41,85.3,76.2" />
      </svg>
    );
  }

  renderDouble() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 157 276">
        <path d="M38.7,149.6c-23,48.5,17.6,111.2,20.7,115.9c57.7-37.7,89.5-104.6,82.4-173.2C137.3,92.8,61.9,100.7,38.7,149.6" />
        <path d="M81.7,26.5C61.2,2.9,18.3,10,15,10.6c6.7,39.6,35,72.1,73.3,84.2C89.1,92.3,102.4,50.2,81.7,26.5" />
      </svg>
    );
  }

  renderDefault() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 111.09 173.16">
        <path d="M7,57.23c-23,48.47,17.56,111.19,20.7,115.93A184.15,184.15,0,0,0,110.1,0C105.66.43,30.26,8.32,7,57.23" />
      </svg>
    );
  }

  getSvg(variant) {
    switch (variant) {
      case 'quote':
        return this.renderQuote();
      case 'double':
        return this.renderDouble();
      default:
        return this.renderDefault();
    }
  }

  getStyle(style, rotation, scale, size) {
    return {
      ...style,
      transform: `scale(${size}) scaleX(${scale}) rotate(${rotation}deg)`,
    };
  }

  render() {
    const { variant, classes, color, className, style, rotation, scaleX, size, ...other } = this.props;

    return (
      <div
        {...other}
        style={this.getStyle(style, rotation, scaleX, size)}
        className={`${classes.container} ${classes[color]} ${className || ''}`}
      >
        {this.getSvg(variant)}
      </div>
    );
  }
}

Eclat.propTypes = {
  classes: PropTypes.object.isRequired,
  variant: PropTypes.oneOf(['quote', 'double', 'default']),
  color: PropTypes.oneOf(['blue', 'orange', 'green', 'yellow', 'red', 'white', 'black']),
  className: PropTypes.string,
  style: PropTypes.object,
  rotation: PropTypes.number,
  scaleX: PropTypes.number,
  size: PropTypes.number,
};

Eclat.defaultProps = {
  variant: 'default',
  color: 'black',
  rotation: 0,
  scaleX: 1,
  size: 1,
};

export default withStyles(styles)(Eclat);
