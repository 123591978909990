export default theme => ({
  paper: {
    backgroundColor: theme.palette.blue.main,
  },
  container: {
    height: '100%',
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(4),
  },
  grid: {
    height: '100%',
    width: '100%',
    flexDirection: 'column',
    display: 'flex',
    flexWrap: 'wrap',
  },
  closeIcon: {
    position: 'absolute',
    top: theme.spacing(2),
    left: 0,
    color: theme.palette.white.main,
  },
  itemContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '50%',
    height: '33.33%',
    padding: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      width: '100%',
      height: 'auto',
      padding: theme.spacing(2),
    },
  },
  itemButton: {
    width: '100%',
    justifyContent: 'flex-start',
    borderRadius: 20,
    '&:hover svg': {
      transform: 'rotate(-45deg)',
    },
  },
  itemNumber: {
    fontFamily: 'Archia',
    fontSize: '11rem',
    fontWeight: 400,
    lineHeight: 1,
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      fontSize: '2rem',
    },
  },
  'itemColor-white': {
    color: theme.palette.white.main,
    '& svg': {
      fill: theme.palette.white.main,
    },
  },
  'itemColor-orange': {
    color: theme.palette.orange.main,
    '& svg': {
      fill: theme.palette.orange.main,
    },
  },
  'itemColor-green': {
    color: theme.palette.green.main,
    '& svg': {
      fill: theme.palette.green.main,
    },
  },
  'itemColor-yellow': {
    color: theme.palette.yellow.main,
    '& svg': {
      fill: theme.palette.yellow.main,
    },
  },
  'itemColor-red': {
    color: theme.palette.red.main,
    '& svg': {
      fill: theme.palette.red.main,
    },
  },
  itemContent: {
    textAlign: 'left',
    color: theme.palette.white.main,
  },
  itemTitle: {
    lineHeight: 1.3,
    [theme.breakpoints.down('xs')]: {
      fontSize: '1rem',
    },
  },
  itemArrow: {
    width: 20,
    transition: 'transform 0.2s',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
});
