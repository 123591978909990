import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { InView } from 'react-intersection-observer';
import CountUp from 'react-countup';
import styles from './styles';

class AnimNumber extends React.PureComponent {
  constructor(props) {
    super(props);
    this.onView = this.onView.bind(this);
    this.state = {
      start: props.value,
      started: false,
    };
  }

  onView(view) {
    const { start, startDiff, value, restartAnimation } = this.props;
    let startValue = start;
    if (start === -1) {
      startValue = value <= startDiff ? 0 : value - startDiff;
    }
    this.setState(prev => {
      if (view && prev.started && !restartAnimation) {
        return null;
      }
      return { start: view ? startValue : value, started: prev.started || view };
    });
  }

  render() {
    const {
      classes,
      component,
      value,
      duration,
      delay,
      separator,
      decimals,
      decimal,
      prefix,
      suffix,
      color,
      containerClassName,
      numberClassName,
      size,
    } = this.props;
    const { start } = this.state;
    return (
      <InView as={component} onChange={this.onView} className={`${classes.container} ${containerClassName || ''}`}>
        <CountUp
          end={value}
          start={start}
          duration={duration}
          delay={delay}
          separator={separator}
          decimals={decimals}
          decimal={decimal}
          prefix={prefix}
          suffix={suffix}
          className={`${classes.number} ${classes[color]} ${classes[size]} ${numberClassName || ''}`}
        />
      </InView>
    );
  }
}

AnimNumber.propTypes = {
  classes: PropTypes.object.isRequired,
  component: PropTypes.string,
  value: PropTypes.number.isRequired,
  containerClassName: PropTypes.string,
  numberClassName: PropTypes.string,
  start: PropTypes.number,
  startDiff: PropTypes.number,
  duration: PropTypes.number,
  delay: PropTypes.number,
  separator: PropTypes.string,
  decimals: PropTypes.number,
  decimal: PropTypes.string,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  color: PropTypes.oneOf(['blue', 'orange', 'green', 'yellow', 'red', 'white', 'black']),
  restartAnimation: PropTypes.bool,
  size: PropTypes.oneOf(['medium', 'small']),
};

AnimNumber.defaultProps = {
  component: 'div',
  start: -1,
  startDiff: 20,
  duration: 2,
  delay: 0,
  separator: '',
  decimals: 0,
  decimal: ',',
  prefix: '',
  suffix: '',
  color: 'black',
  restartAnimation: false,
  size: 'medium',
};

export default withStyles(styles)(AnimNumber);
