export default theme => ({
  footer: {
    backgroundColor: theme.palette.grey.main,
    marginTop: theme.spacing(12),
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  logoRepFr: {
    height: 100,
  },
  logoAnses: {
    height: 60,
    marginBottom: 40,
  },
  social: {},
  buttons: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  btnDownload: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  credits: {
    marginTop: theme.spacing(1),
    fontSize: '0.7rem',
  },
});
