export default theme => ({
  blockContainer: {
    position: 'relative',
  },
  container: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  video: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  imageIntro: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  quoteBackground: {
    backgroundColor: theme.palette.secondary.main,
  },
  quoteBlock: {
    marginBottom: theme.spacing(8),
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  imgWrapper: {
    position: 'relative',
    flex: 1,
  },
  imgRound: {
    position: 'relative',
    width: 400,
    height: 400,
    borderRadius: 500,
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      width: 250,
      height: 250,
    },
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center top',
  },
  eclatQuoteImg: {
    position: 'absolute',
    width: 150,
    top: -theme.spacing(2),
    right: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      bottom: -theme.spacing(2),
      left: theme.spacing(2),
      width: 75,
    },
  },
  eclatQuote: {
    position: 'absolute',
    bottom: theme.spacing(4),
    width: 110,
    right: 'calc(40% - 110px)',
    [theme.breakpoints.down('sm')]: {
      width: 60,
    },
  },
  quoteWrapper: {
    position: 'relative',
    padding: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
    },
  },
  quote: {
    paddingBottom: theme.spacing(2),
  },
  autor: {
    paddingBottom: theme.spacing(1),
  },
  role: {
    paddingBottom: theme.spacing(2),
  },
  blue: {
    color: theme.palette.blue.main,
  },
  numbersBackground: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    backgroundColor: theme.palette.grey.main,
  },
  numbersTitle: {
    position: 'relative',
    zIndex: 2,
    textAlign: 'center',
    '&:after': {
      content: '""',
      display: 'inline-block',
      position: 'relative',
      left: theme.spacing(2),
      height: 47,
      width: 47,
      backgroundSize: '47px 47px',
      backgroundImage: `url(${theme.url('/images/arrowDiagonalBlue.svg')})`,
      backgroundRepeat: 'no-repeat',
      [theme.breakpoints.down('md')]: {
        height: 40,
        width: 40,
        backgroundSize: '40px 40px',
      },
      [theme.breakpoints.down('xs')]: {
        left: theme.spacing(1),
        height: 20,
        width: 20,
        backgroundSize: '20px 20px',
      },
    },
  },
  numbersSubtitle: {
    position: 'relative',
    zIndex: 2,
    maxWidth: 800,
    margin: 'auto',
    fontWeight: 'bold',
    textAlign: 'center',
    textTransform: 'uppercase',
    padding: theme.spacing(12, 8, 0, 8),
    color: theme.palette.orange.main,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(8, 1, 0, 1),
    },
  },
  row: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing(8),
    margin: theme.spacing(0, -4, 0, -4),
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      textAlign: 'center',
      margin: theme.spacing(0, -2, 0, -2),
      paddingTop: theme.spacing(2),
      '&:first-child': {
        paddingTop: theme.spacing(8),
      },
    },
  },
  number: {
    position: 'relative',
    zIndex: 2,
    display: 'inline-block',
    textAlign: 'center',
    paddingBottom: theme.spacing(2),
    margin: theme.spacing(0, 4, 0, 4),
    borderBottom: `solid 5px ${theme.palette.blue.main}`,
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(1),
      margin: theme.spacing(2),
    },
  },
  eclatNumbersTop: {
    position: 'absolute',
    top: -120,
    left: '5%',
    width: 150,
    [theme.breakpoints.down('sm')]: {
      width: 100,
    },
  },
  eclatNumbersBottom: {
    position: 'absolute',
    top: 250,
    right: '5%',
    width: 150,
    [theme.breakpoints.down('sm')]: {
      width: 100,
      top: 'initial',
      bottom: 0,
    },
  },
});
