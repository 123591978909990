import React from 'react';
import PropTypes from 'prop-types';
import ButtonBase from '@material-ui/core/ButtonBase';
import { withStyles } from '@material-ui/core/styles';
import { ReactComponent as Add } from '../../res/add.svg';
import { ReactComponent as Download } from '../../res/download.svg';

import styles from './styles';

class Button extends React.PureComponent {
  renderIcon(iconClass) {
    if (this.props.size === 'small') {
      return this.renderIconDownload(iconClass);
    }
    return this.renderIconAdd(iconClass);
  }

  renderIconDownload(iconClass) {
    const { classes } = this.props;
    return <Download className={classes[iconClass]} />;
  }

  renderIconAdd(iconClass) {
    const { classes, underline, color } = this.props;
    return <Add className={`${classes[iconClass]} ${underline ? classes[`${color}-iconUnderline`] : ''}`} />;
  }

  render() {
    const { children, classes, className, color, size, inverse, underline, noIcon, noBorder, ...other } = this.props;
    let colorClass = inverse ? `${color}-inverse` : color;
    colorClass = underline ? `${color}-underline` : colorClass;
    const labelClass = underline ? classes[`${color}-labelUnderline`] : '';
    const iconClass = `icon-${size}`;
    return (
      <ButtonBase
        focusRipple
        className={`${classes.button} ${className || ''} ${classes[colorClass]} ${classes[size]} ${
          noBorder ? classes.noBorder : ''
        }`}
        {...other}
      >
        {noIcon ? null : this.renderIcon(iconClass)}
        <span className={`${classes.label} ${labelClass} ${classes[`label-${size}`]}`}>{children}</span>
      </ButtonBase>
    );
  }
}

Button.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.any,
  className: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.string,
  inverse: PropTypes.bool,
  underline: PropTypes.bool,
  noIcon: PropTypes.bool,
  noBorder: PropTypes.bool,
};

Button.defaultProps = {
  color: 'grey',
  size: 'medium',
  inverse: false,
  underline: false,
  noIcon: false,
};

export default withStyles(styles)(Button);
