export default theme => ({
  container: {
    textAlign: 'left',
    backgroundColor: theme.palette.white.main,
    position: 'relative',
  },
  card: {
    position: 'relative',
  },
  grid: {
    position: 'relative',
  },
  gridItem: {
    width: '100%',
  },
  imageContainer: {
    position: 'relative',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    height: '100%',
    width: '100%',
    minHeight: 300,
    [theme.breakpoints.down('md')]: {
      minHeight: 500,
    },
    [theme.breakpoints.down('xs')]: {
      minHeight: 600,
    },
  },
  textContent: {
    position: 'relative',
    zIndex: 3,
    minHeight: '100%',
    padding: theme.spacing(6),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
    },
  },
  blue: {
    backgroundColor: theme.palette.blue.main,
    color: theme.palette.blue.contrastText,
  },
  orange: {
    backgroundColor: theme.palette.orange.main,
    color: theme.palette.orange.contrastText,
  },
  green: {
    backgroundColor: theme.palette.green.main,
    color: theme.palette.green.contrastText,
  },
  yellow: {
    backgroundColor: theme.palette.yellow.main,
    color: theme.palette.yellow.contrastText,
  },
  red: {
    backgroundColor: theme.palette.red.main,
    color: theme.palette.red.contrastText,
  },
  white: {
    backgroundColor: theme.palette.white.main,
    color: theme.palette.white.contrastText,
  },
  black: {
    backgroundColor: theme.palette.black.main,
    color: theme.palette.black.contrastText,
  },
  additionalText: {
    position: 'absolute',
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    paddingTop: theme.spacing(4),
    paddingRight: theme.spacing(1),
    bottom: 0,
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2),
      paddingTop: theme.spacing(3),
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1),
      paddingTop: theme.spacing(3),
      '& p': {
        fontSize: '0.8rem',
      },
    },
  },
  rectangle: {
    position: 'absolute',
    zIndex: 2,
    top: 0,
    left: '50%',
    width: 40,
    height: 40,
    transform: 'translate(-50%, -50%) rotate(45deg)',
  },
  imageTitle: {
    width: '100%',
    textTransform: 'initial',
    color: theme.palette.primary.main,
    padding: theme.spacing(0, 4, 2, 4),
  },
  whiteImageTitle: {
    color: theme.palette.white.main,
  },
  imageSubtitle: {
    width: '100%',
    textTransform: 'initial',
    backgroundColor: 'rgba(255,255,255,0.6)',
    color: theme.palette.primary.main,
    padding: theme.spacing(2, 4, 2, 4),
  },
  buttonVideo: {
    textAlign: 'left',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    paddingBottom: theme.spacing(2),
  },
  playIcon: {
    marginLeft: theme.spacing(4),
    stroke: theme.palette.black.main,
    fill: theme.palette.black.main,
  },
  iconWhiteImageTitle: {
    stroke: theme.palette.white.main,
    fill: theme.palette.white.main,
  },
});
