/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import AnimNumber from '../../components/AnimNumber';
import Card from '../../components/Card';
import Button from '../../components/Button';
import Title from '../../components/Title';
import Anchor from '../../components/Anchor';
import PdfLoader from '../../utils/PdfLoader';
import parse from '../../utils/Parser';
import styles from './styles';

class B4KnownRisks extends React.Component {
  renderNumbers1() {
    const { classes, data } = this.props;
    return (
      <div className={classes.numbersBlock}>
        <Typography variant="subtitle1" className={classes.numbersTitle}>
          {parse(data.b4knownRisks.numbersTitle1)}
        </Typography>
        <div className={`${classes.row} ${classes.row1}`}>
          <div className={classes.number}>
            <AnimNumber
              color="yellow"
              value={data.b4knownRisks.number1}
              suffix={parse(data.b4knownRisks.number1suffix)}
            ></AnimNumber>
            <Typography variant="subtitle1">{parse(data.b4knownRisks.number1type)}</Typography>
          </div>
          <div className={classes.number}>
            <AnimNumber
              color="yellow"
              suffix={parse(data.b4knownRisks.number2suffix)}
              value={data.b4knownRisks.number2}
            ></AnimNumber>
            <Typography variant="subtitle1">{parse(data.b4knownRisks.number2type)}</Typography>
          </div>
          <div className={`${classes.number} ${classes.longNumber}`}>
            <AnimNumber
              color="yellow"
              size="small"
              separator={parse(data.b4knownRisks.number3separator)}
              prefix={parse(data.b4knownRisks.number3prefix)}
              value={data.b4knownRisks.number3}
            ></AnimNumber>
            <Typography variant="subtitle1">{parse(data.b4knownRisks.number3type)}</Typography>
          </div>
        </div>
        <div className={`${classes.row} ${classes.row2}`}>
          <div className={classes.number}>
            <AnimNumber
              color="yellow"
              separator={parse(data.b4knownRisks.number4separator)}
              prefix={parse(data.b4knownRisks.number4prefix)}
              value={data.b4knownRisks.number4}
            ></AnimNumber>
            <Typography variant="subtitle1">{parse(data.b4knownRisks.number4type)}</Typography>
          </div>
          <div className={classes.number}>
            <AnimNumber
              color="yellow"
              value={data.b4knownRisks.number5}
              prefix={parse(data.b4knownRisks.number5prefix)}
              suffix={parse(data.b4knownRisks.number5suffix)}
            ></AnimNumber>
            <Typography variant="subtitle1">{parse(data.b4knownRisks.number5type)}</Typography>
          </div>
        </div>
      </div>
    );
  }

  renderNumbers2() {
    const { classes, data } = this.props;
    return (
      <div className={classes.numbersBlock}>
        <Typography variant="subtitle1" className={classes.numbersTitle}>
          {parse(data.b4knownRisks.numbersTitle2)}
        </Typography>
        <div className={classes.row}>
          <div className={classes.number}>
            <AnimNumber
              color="yellow"
              value={data.b4knownRisks.number6}
              suffix={parse(data.b4knownRisks.number6suffix)}
            ></AnimNumber>
            <Typography variant="subtitle1">{parse(data.b4knownRisks.number6type)}</Typography>
          </div>
          <div className={classes.number}>
            <AnimNumber
              color="yellow"
              value={data.b4knownRisks.number7}
              suffix={parse(data.b4knownRisks.number7suffix)}
            ></AnimNumber>
            <Typography variant="subtitle1">{parse(data.b4knownRisks.number7type)}</Typography>
          </div>
        </div>
      </div>
    );
  }

  renderInner() {
    const { classes, data } = this.props;

    return (
      <Container maxWidth="lg" className={classes.container}>
        <Typography variant="h5" className={classes.subtitle}>
          {parse(data.b4knownRisks.subtitle1)}
        </Typography>
        <Card
          color="yellow"
          imgSrc={parse(data.b4knownRisks.card1Img)}
          additionalText={parse(data.b4knownRisks.card1Desc)}
          imgLeft
          rectangle
          className={classes.card}
        >
          <Typography variant="body1" className={classes.text}>
            {parse(data.b4knownRisks.card1Desc)}
          </Typography>
        </Card>
        {this.renderNumbers1()}
        <Typography variant="h5" className={classes.subtitle}>
          {parse(data.b4knownRisks.subtitle2)}
        </Typography>
        <Card color="yellow" imgSrc={parse(data.b4knownRisks.card2Img)} rectangle className={classes.card}>
          <Typography variant="body1">{parse(data.b4knownRisks.card2Desc)}</Typography>
        </Card>
        {this.renderNumbers2()}
        <div className={classes.centeredButton}>
          <Button color="yellow" className={classes.button} onClick={PdfLoader(data.b4knownRisks.pdf, 'knownRisks')}>
            <Typography variant="button">{parse(data.b4knownRisks.btn)}</Typography>
          </Button>
        </div>
      </Container>
    );
  }

  render() {
    const { classes, data } = this.props;
    return (
      <div className={classes.blockContainer}>
        <Anchor id="B4KnownRisks" />
        <Title color="yellow" title={parse(data.b4knownRisks.title)}></Title>
        {this.renderInner()}
      </div>
    );
  }
}

B4KnownRisks.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object,
  // attClick: PropTypes.func,
};

export default withStyles(styles)(B4KnownRisks);
