import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider, withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import CircularProgress from '@material-ui/core/CircularProgress';
import parseJson from 'parse-json';
import theme from '../../utils/theme';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import B0Intro from '../../blocks/B0Intro';
import B1Covid from '../../blocks/B1Covid';
import B2HumansAnimals from '../../blocks/B2HumansAnimals';
import B3Exposures from '../../blocks/B3Exposures';
import B4KnownRisks from '../../blocks/B4KnownRisks';
import B5Science from '../../blocks/B5Science';
import ScrollTop from '../../components/ScrollTop';

import DeviceInfos from '../../utils/DeviceInfos';
import styles from './styles';

const BASE_URL = !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? '' : process.env.PUBLIC_URL;

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      error: null,
      lang: this.getDefaultLang(),
    };
    this.setLang = this.setLang.bind(this);
  }

  getDefaultLang() {
    const { language } = navigator;
    return language.indexOf('fr') !== -1 ? 'fr' : 'en';
  }

  setLang(lang) {
    this.setState({ lang });
  }

  attClick(e, name, type = 'navigation') {
    window.ATTag.click.send({
      elem: e.target,
      name,
      level2: 'clickLvl2',
      type,
    });
  }

  async componentDidMount() {
    const resp = await fetch('./data.json', { cache: 'no-store' });
    const json = await resp.text();
    let data;
    try {
      data = parseJson(json);
    } catch (error) {
      this.setState({ error });
      return;
    }
    if (DeviceInfos.device.type === 'desktop') {
      const videoIntroUrl = `${BASE_URL}/videos/${data[this.state.lang].b0intro.video}`;
      const req = new XMLHttpRequest();
      req.onload = () => {
        if (req.status === 200 && !this.state.data && !this.state.error) {
          const videoBlob = req.response;
          const vid = URL.createObjectURL(videoBlob);
          data[this.state.lang].b0intro.video = vid;
          this.setState({ data });
        }
      };
      req.open('GET', videoIntroUrl, true);
      req.setRequestHeader('Cache-Control', 'public');
      req.responseType = 'blob';
      req.send();
    } else {
      this.setState({ data });
    }
  }

  renderError() {
    const { error } = this.state;
    if (!error) {
      return null;
    }
    return (
      <div style={{ padding: 30 }}>
        <h2>Erreur dans le parsing du fichier</h2>
        <pre>{error.message}</pre>
      </div>
    );
  }

  renderLoading() {
    const { data, error } = this.state;
    if (data || error) {
      return null;
    }
    return (
      <div style={{ width: '100%', paddingTop: '35vh', textAlign: 'center' }}>
        <h1 style={{ width: '100%', textAlign: 'center', marginBottom: 50 }}>Chargement...</h1>
        <CircularProgress />
      </div>
    );
  }

  renderApp() {
    const { data: allData, error, lang } = this.state;
    const { classes } = this.props;
    if (!allData || error) {
      return null;
    }
    const data = allData[lang];
    return (
      <div className={classes.mainContainer}>
        <Header attClick={this.attClick} data={data} baseUrl={BASE_URL} onSetLang={this.setLang} lang={lang} />
        <B0Intro attClick={this.attClick} data={data} baseUrl={BASE_URL} />
        <ScrollTop attClick={this.attClick} data={data} baseUrl={BASE_URL} />
        <B1Covid attClick={this.attClick} data={data} baseUrl={BASE_URL} />
        <B2HumansAnimals attClick={this.attClick} data={data} baseUrl={BASE_URL} lang={lang} />
        <B3Exposures attClick={this.attClick} data={data} baseUrl={BASE_URL} />
        <B4KnownRisks attClick={this.attClick} data={data} baseUrl={BASE_URL} />
        <B5Science attClick={this.attClick} data={data} baseUrl={BASE_URL} />
        <Footer attClick={this.attClick} data={data} baseUrl={BASE_URL} />
      </div>
    );
  }

  render() {
    return (
      <>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {this.renderLoading()}
          {this.renderError()}
          {this.renderApp()}
        </ThemeProvider>
      </>
    );
  }
}

App.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(App);
