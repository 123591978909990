export default theme => ({
  blockContainer: {
    position: 'relative',
  },
  card: {
    margin: theme.spacing(8, 0, 8, 0),
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(2, 0, 2, 0),
    },
  },
  subtitle: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    padding: theme.spacing(2, 0, 2, 0),
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(4),
  },
  centeredButton: {
    textAlign: 'center',
    marginTop: theme.spacing(8),
  },
  text: {
    '& em': {
      fontStyle: 'normal',
      fontSize: '2em',
    },
  },
  row: {
    display: 'flex',
    justifyContent: 'center',
    // gap: theme.spacing(4),
    margin: theme.spacing(0, -4, 0, -4),
    paddingTop: theme.spacing(8),
    '& > *': {
      width: '100%',
    },
  },
  number: {
    position: 'relative',
    zIndex: 2,
    display: 'inline-block',
    textAlign: 'center',
    margin: theme.spacing(0, 4, 0, 4),
    paddingBottom: theme.spacing(2),
    borderBottom: `solid 5px ${theme.palette.yellow.main}`,
  },
  numbersTitle: {
    position: 'relative',
    zIndex: 2,
    textAlign: 'center',
    textDecoration: 'underline',
    textTransform: 'uppercase',
    marginTop: theme.spacing(4),
  },
  numbersBlock: {
    marginBottom: theme.spacing(8),
  },
  quoteBackground: {
    backgroundColor: theme.palette.secondary.main,
  },
  quoteBlock: {
    marginBottom: theme.spacing(8),
    display: 'flex',
    justifyContent: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  secondQuote: {
    marginTop: theme.spacing(4),
  },
  eclatQuoteTop: {
    position: 'absolute',
    width: 150,
    top: -theme.spacing(1),
    right: 0,
    [theme.breakpoints.down('sm')]: {
      width: 75,
      top: 'initial',
      right: 'initial',
      bottom: -theme.spacing(2),
      left: theme.spacing(2),
    },
  },
  eclatSecondQuoteTop: {
    right: -theme.spacing(2),
  },
  eclatQuote: {
    position: 'absolute',
    bottom: theme.spacing(2),
    width: 110,
    right: 'calc(40% - 110px)',
    [theme.breakpoints.down('sm')]: {
      width: 60,
      top: 'initial',
      bottom: -theme.spacing(4),
    },
  },
  eclatSecondQuote: {
    [theme.breakpoints.down('xs')]: {
      bottom: -theme.spacing(4),
    },
  },
  imgWrapper: {
    position: 'relative',
  },
  imgRound: {
    position: 'relative',
    width: 400,
    height: 400,
    borderRadius: 500,
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      width: 250,
      height: 250,
    },
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center bottom',
  },
  quoteWrapper: {
    position: 'relative',
    padding: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
    },
  },
  quote: {
    paddingBottom: theme.spacing(4),
    '& b': {
      color: theme.palette.red.main,
    },
  },
  autor: {
    paddingBottom: theme.spacing(1),
    color: theme.palette.red.main,
  },
  role: {
    paddingBottom: theme.spacing(2),
    color: theme.palette.red.main,
  },
  descAutor: {
    display: 'inline-block',
    marginTop: theme.spacing(2),
  },
  eclatTop: {
    position: 'relative',
    top: -10,
    right: 60,
    [theme.breakpoints.down('sm')]: {
      width: 50,
      right: 'initial',
      left: 0,
    },
  },
  eclatBottom: {
    position: 'relative',
    top: 10,
    left: '80%',
    [theme.breakpoints.down('sm')]: {
      width: 50,
    },
  },
});
