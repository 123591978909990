import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Fab from '@material-ui/core/Fab';
import CloseIcon from '@material-ui/icons/Close';
import ReactPlayer from 'react-player';
import { ReactComponent as Play } from '../../res/play.svg';
import styles from './styles';

class PopupInterview extends React.PureComponent {
  renderVideo() {
    if (this.props.useIframe) {
      return this.renderIframe();
    }
    return this.renderPlayer();
  }

  renderIframe() {
    const { videoUrl } = this.props;
    return (
      <iframe
        title={videoUrl}
        width="100%"
        height="100%"
        src={videoUrl}
        scrolling="no"
        frameBorder="0"
        style={{ border: 'none', overflow: 'hidden' }}
      ></iframe>
    );
  }

  renderPlayer() {
    const { classes, videoUrl } = this.props;
    return (
      <ReactPlayer
        url={videoUrl}
        playIcon={<Play className={classes.playIcon} />}
        width="100%"
        height="100%"
        playing
        controls
        className={classes.player}
      />
    );
  }

  render() {
    const { classes, open, onClose } = this.props;
    return (
      <Dialog
        maxWidth="md"
        fullWidth
        scroll="paper"
        open={open || false}
        onClose={onClose}
        PaperProps={{ square: true, className: classes.dialogPaper }}
      >
        <Fab color="primary" onClick={onClose} className={classes.closeIcon}>
          <CloseIcon fontSize="large" />
        </Fab>
        <DialogContent className={classes.dialogContent}>{this.renderVideo()}</DialogContent>
      </Dialog>
    );
  }
}

PopupInterview.propTypes = {
  classes: PropTypes.object.isRequired,
  videoUrl: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  useIframe: PropTypes.bool,
};

export default withStyles(styles)(PopupInterview);
