export default theme => ({
  paper: {
    backgroundColor: theme.palette.blue.main,
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(1),
      width: 'calc(100% - 16px)',
    },
  },
  container: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(7),
    color: theme.palette.white.main,
    fill: theme.palette.white.main,
    '& a': {
      color: theme.palette.white.main,
    },
  },
  closeIcon: {
    position: 'absolute',
    top: theme.spacing(2),
    left: theme.spacing(2),
    color: theme.palette.white.main,
  },
  title: {
    marginBottom: theme.spacing(4),
  },
  mainArrow: {
    paddingLeft: theme.spacing(2),
    width: 50,
    [theme.breakpoints.down('xs')]: {
      width: 30,
    },
  },
  subtitle: {
    marginBottom: theme.spacing(8),
  },
  layout: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  columnLeft: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: theme.spacing(16),
  },
  columnRight: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  part: {
    position: 'relative',
    marginBottom: theme.spacing(4),
    '& .h4': {
      textTransform: 'uppercase',
      fontWeight: 700,
    },
  },
  partTitle: {
    position: 'relative',
    zIndex: 2,
  },
  partText: {
    position: 'relative',
    zIndex: 2,
    maxWidth: 450,
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    '& u': {
      fontWeight: 700,
    },
    '& b': {
      display: 'inline-block',
      paddingLeft: theme.spacing(2),
    },
    '& b:before': {
      content: '""',
      display: 'inline-block',
      position: 'relative',
      right: theme.spacing(1),
      height: 10,
      width: 10,
      backgroundSize: '10px 10px',
      backgroundImage: `url(${theme.url('/images/arrowDiagonal.svg')})`,
      backgroundRepeat: 'no-repeat',
    },
  },
  partOrange: {
    borderLeft: `solid 2px ${theme.palette.orange.main}`,
  },
  partRed: {
    borderLeft: `solid 2px ${theme.palette.red.main}`,
  },
  partYellow: {
    borderLeft: `solid 2px ${theme.palette.yellow.main}`,
  },
  partGreen: {
    borderLeft: `solid 2px ${theme.palette.green.main}`,
  },
  partWhite: {
    borderLeft: `solid 2px ${theme.palette.white.main}`,
  },
  eclatOrange: {
    position: 'absolute',
    zIndex: 1,
    width: 150,
    right: '-60%',
    [theme.breakpoints.down('sm')]: {
      width: 80,
      right: '5%',
    },
    [theme.breakpoints.down('xs')]: {
      width: 80,
      right: '15%',
    },
  },
  eclatYellow: {
    position: 'absolute',
    zIndex: 1,
    width: 100,
    top: -150,
    right: 0,
    [theme.breakpoints.down('sm')]: {
      right: '15%',
    },
    [theme.breakpoints.down('xs')]: {
      right: 'initial',
      left: '5%',
    },
  },
  eclatGreen: {
    position: 'absolute',
    zIndex: 1,
    width: 150,
    top: -200,
    right: 0,
    [theme.breakpoints.down('sm')]: {
      width: 80,
      right: '5%',
      top: 'initial',
      bottom: 0,
    },
    [theme.breakpoints.down('xs')]: {
      width: 80,
      right: '10%',
      top: 'initial',
      bottom: -100,
    },
  },
  eclatRed: {
    position: 'absolute',
    zIndex: 1,
    width: 120,
    right: '-40%',
    bottom: '5%',
    [theme.breakpoints.down('sm')]: {
      width: 80,
      right: '5%',
      bottom: 'initial',
      top: 150,
    },
    [theme.breakpoints.down('xs')]: {
      width: 80,
      left: '10%',
      bottom: 'initial',
      top: -150,
    },
  },
});
