import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import Twitter from '@material-ui/icons/Twitter';
import LinkedIn from '@material-ui/icons/LinkedIn';
import YouTube from '@material-ui/icons/YouTube';
import Facebook from '@material-ui/icons/Facebook';

import Grid from '../Grid';
import Button from '../Button';
import PdfLoader from '../../utils/PdfLoader';
import Menu from '../../popups/Menu';
import parse from '../../utils/Parser';
import styles from './styles';

class Header extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { menuOpen: false };
    this.onMenuOpen = this.onMenuOpen.bind(this);
    this.onMenuClose = this.onMenuClose.bind(this);
  }

  onMenuOpen() {
    this.setState({ menuOpen: true });
  }

  onMenuClose() {
    this.setState({ menuOpen: false });
  }

  render() {
    const { classes, lang, baseUrl, data, attClick } = this.props;
    const { menuOpen } = this.state;
    return (
      <>
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <Container className={classes.container}>
              <Grid justify="space-between" alignItems="center">
                <Grid key="left" alignItems="center" spacing={4} itemClassName={classes.leftItem}>
                  <IconButton key="menuBtn" edge="start" color="inherit" aria-label="menu" onClick={this.onMenuOpen}>
                    <MenuIcon />
                  </IconButton>
                  <a
                    key="logo"
                    href={data.global.siteAnses}
                    target="_blank"
                    className={classes.logoLink}
                    onClick={e => attClick(e, 'anses')}
                  >
                    <img key="logo" src={`${baseUrl}/images/logo_white.svg`} alt="logo" className={classes.logo} />
                  </a>
                </Grid>
                <Grid key="right" alignItems="center" wrap="nowrap" xs="auto" className={classes.gridRight}>
                  <div key="social" className={classes.socialContainer}>
                    <IconButton
                      onClick={e => attClick(e, 'twitter')}
                      color="inherit"
                      component="a"
                      target="_blank"
                      href={data.global.twitter}
                    >
                      <Twitter />
                    </IconButton>
                    <IconButton
                      onClick={e => attClick(e, 'linkedin')}
                      color="inherit"
                      component="a"
                      target="_blank"
                      href={data.global.linkedin}
                    >
                      <LinkedIn />
                    </IconButton>
                    <IconButton
                      onClick={e => attClick(e, 'youtube')}
                      color="inherit"
                      component="a"
                      target="_blank"
                      href={data.global.youtube}
                    >
                      <YouTube />
                    </IconButton>
                    <IconButton
                      onClick={e => attClick(e, 'facebook')}
                      color="inherit"
                      component="a"
                      target="_blank"
                      href={data.global.facebook}
                    >
                      <Facebook />
                    </IconButton>
                  </div>
                  <Button
                    onClick={PdfLoader(data.global.reportPdf, 'reportPdf')}
                    key="ra-print"
                    color="orange"
                    inverse
                    size="small"
                    className={classes.btnDownload}
                  >
                    <Typography variant="overline">{parse(data.header.buttonLabel)}</Typography>
                  </Button>
                  <div key="lang">
                    <IconButton
                      className={`${classes.buttonLang} ${lang === 'fr' ? classes.langActive : ''}`}
                      onClick={() => this.props.onSetLang('fr')}
                    >
                      FR
                    </IconButton>
                    |
                    <IconButton
                      className={`${classes.buttonLang} ${lang === 'en' ? classes.langActive : ''}`}
                      onClick={() => this.props.onSetLang('en')}
                    >
                      EN
                    </IconButton>
                  </div>
                </Grid>
              </Grid>
            </Container>
          </Toolbar>
        </AppBar>
        <Menu open={menuOpen} onClose={this.onMenuClose} data={data} />
      </>
    );
  }
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  baseUrl: PropTypes.string,
  data: PropTypes.object,
  onSetLang: PropTypes.func,
  lang: PropTypes.string,
  attClick: PropTypes.func,
};

export default withStyles(styles)(Header);
