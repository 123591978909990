export default () => ({
  container: {
    position: 'absolute',
    width: '100%',
    pointerEvents: 'none',
    height: 0,
    let: 0,
    top: -100,
  },
});
