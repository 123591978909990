/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import ButtonBase from '@material-ui/core/ButtonBase';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Eclat from '../../components/Eclat';
import Card from '../../components/Card';
import Button from '../../components/Button';
import Title from '../../components/Title';
import Anchor from '../../components/Anchor';
import styles from './styles';
import { ReactComponent as Podcast } from '../../res/podcast.svg';
import PopupInterview from '../../components/PopupInterview';
import PdfLoader from '../../utils/PdfLoader';
import parse from '../../utils/Parser';

class B2HumansAnimals extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      podcast: null,
    };
  }

  onClosePodcast() {
    this.setState({ podcast: null });
  }

  onOpenPodcast(e, name, podcast) {
    this.props.attClick(e, name, 'action');
    this.setState({ podcast });
  }

  renderQuote() {
    const { classes, data, baseUrl } = this.props;
    return (
      <div className={classes.quoteBackground}>
        <div className={classes.quoteBlock}>
          <div className={classes.imgWrapper}>
            <div className={classes.imgRound}>
              <img src={`${baseUrl}/blocks/${data.b2humansAnimals.quoteImg}`} alt="" className={classes.image} />
            </div>
            <Eclat color="orange" variant="quote" className={classes.eclatQuoteTop} rotation={0}></Eclat>
          </div>
          <div className={classes.quoteWrapper}>
            <Typography variant="body1" className={classes.quote}>
              {parse(data.b2humansAnimals.quote)}
            </Typography>
            <Typography variant="h5" className={classes.autor}>
              {parse(data.b2humansAnimals.autor)}
            </Typography>
            <Typography variant="h6" className={classes.role}>
              {parse(data.b2humansAnimals.role)}
            </Typography>
            <Eclat color="orange" variant="quote" className={classes.eclatQuote} rotation={200}></Eclat>
          </div>
        </div>
      </div>
    );
  }

  renderPodcasts() {
    const { classes, data, lang } = this.props;
    const { podcast } = this.state;
    return (
      <div className={classes.podcasts}>
        {lang === 'fr' && (
          <div>
            <PopupInterview useIframe open={!!podcast} videoUrl={podcast} onClose={() => this.onClosePodcast()} />
            <Typography variant="h5" className={classes.subtitle}>
              {parse(data.b2humansAnimals.subtitle2)}
            </Typography>
            <div className={classes.podcastsWrapper}>
              <ButtonBase
                onClick={e => this.onOpenPodcast(e, 'podcastCarnivore', data.b2humansAnimals.podcast1url)}
                className={`${classes.imageContainer} ${classes.podcast} ${classes.one} ${classes.buttonVideo}`}
              >
                <Podcast className={`iconPodcast ${classes.iconPodcast}`}></Podcast>
                <Typography variant="h4" className={classes.textPodcast}>
                  {parse(data.b2humansAnimals.podcast1title)}
                </Typography>
              </ButtonBase>
              <ButtonBase
                onClick={e => this.onOpenPodcast(e, 'podcastHommesMalades', data.b2humansAnimals.podcast2url)}
                className={`${classes.imageContainer} ${classes.podcast} ${classes.two} ${classes.buttonVideo}`}
              >
                <Podcast className={`iconPodcast ${classes.iconPodcast}`}></Podcast>
                <Typography variant="body1" className={classes.textPodcast}>
                  {parse(data.b2humansAnimals.podcast2title)}
                </Typography>
              </ButtonBase>
              <ButtonBase
                onClick={e => this.onOpenPodcast(e, 'podcastAbeilles', data.b2humansAnimals.podcast3url)}
                className={`${classes.imageContainer} ${classes.podcast} ${classes.three} ${classes.buttonVideo}`}
              >
                <Podcast className={`iconPodcast ${classes.iconPodcast}`}></Podcast>
                <Typography variant="body1" className={classes.textPodcast}>
                  {parse(data.b2humansAnimals.podcast3title)}
                </Typography>
              </ButtonBase>
              <ButtonBase
                onClick={e => this.onOpenPodcast(e, 'podcastTiques', data.b2humansAnimals.podcast4url)}
                className={`${classes.imageContainer} ${classes.podcast} ${classes.four} ${classes.buttonVideo}`}
              >
                <Podcast className={`iconPodcast ${classes.iconPodcast}`}></Podcast>
                <Typography variant="body1" className={classes.textPodcast}>
                  {parse(data.b2humansAnimals.podcast4title)}
                </Typography>
              </ButtonBase>
              <ButtonBase
                onClick={e => this.onOpenPodcast(e, 'podcastRegard', data.b2humansAnimals.podcast5url)}
                className={`${classes.imageContainer} ${classes.podcast} ${classes.five} ${classes.buttonVideo}`}
              >
                <Podcast className={`iconPodcast ${classes.iconPodcast}`}></Podcast>
                <Typography variant="body1" className={classes.textPodcast}>
                  {parse(data.b2humansAnimals.podcast5title)}
                </Typography>
              </ButtonBase>
            </div>
          </div>
        )}
        <div className={classes.centeredButton}>
          <Button
            color="orange"
            className={classes.button}
            onClick={PdfLoader(data.b2humansAnimals.pdf, 'humansAnimals')}
          >
            <Typography variant="button">{parse(data.b2humansAnimals.btn)}</Typography>
          </Button>
        </div>
      </div>
    );
  }

  renderInner() {
    const { classes, data } = this.props;

    return (
      <Container maxWidth="lg" className={classes.container}>
        <Card color="orange" imgSrc={parse(data.b2humansAnimals.card1Img)} className={classes.card} rectangle>
          <Typography variant="h4" className={classes.descTitle}>
            {parse(data.b2humansAnimals.card1DescTitle)}
          </Typography>
          <Typography variant="body1">{parse(data.b2humansAnimals.card1Desc)}</Typography>
        </Card>
        <Typography variant="h5" className={classes.subtitle}>
          {parse(data.b2humansAnimals.subtitle1)}
        </Typography>
        {this.renderQuote()}
        <Card color="orange" imgSrc={parse(data.b2humansAnimals.card2Img)} className={classes.card} rectangle>
          <Typography variant="h4" className={classes.descTitle}>
            {parse(data.b2humansAnimals.card2DescTitle)}
          </Typography>
          <Typography variant="body1">{parse(data.b2humansAnimals.card2Desc)}</Typography>
        </Card>
        {this.renderPodcasts()}
      </Container>
    );
  }

  render() {
    const { classes, data } = this.props;
    return (
      <div className={classes.blockContainer}>
        <Anchor id="B2HumansAnimals" />
        <Title color="orange" title={parse(data.b2humansAnimals.title)}></Title>
        {this.renderInner()}
      </div>
    );
  }
}

B2HumansAnimals.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object,
  lang: PropTypes.string,
  baseUrl: PropTypes.string,
  attClick: PropTypes.func,
};

export default withStyles(styles)(B2HumansAnimals);
