export default theme => ({
  container: {
    position: 'absolute',
    width: 100,
  },
  blue: {
    fill: theme.palette.blue.main,
  },
  orange: {
    fill: theme.palette.orange.main,
  },
  green: {
    fill: theme.palette.green.main,
  },
  yellow: {
    fill: theme.palette.yellow.main,
  },
  red: {
    fill: theme.palette.red.main,
  },
  white: {
    fill: theme.palette.white.main,
  },
  black: {
    fill: theme.palette.black.main,
  },
});
