/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Eclat from '../../components/Eclat';
import Button from '../../components/Button';
import Title from '../../components/Title';
import Anchor from '../../components/Anchor';
import Risks from '../../popups/Risks';
import PdfLoader from '../../utils/PdfLoader';
import parse from '../../utils/Parser';
import styles from './styles';

class B5Science extends React.Component {
  constructor(props) {
    super(props);
    this.state = { openRisks: false };
    this.onCloseRisks = this.onCloseRisks.bind(this);
    this.onOpenRisks = this.onOpenRisks.bind(this);
  }

  onCloseRisks() {
    this.setState({ openRisks: false });
  }

  onOpenRisks(e) {
    this.props.attClick(e, 'popupRisk', 'action');
    this.setState({ openRisks: true });
  }

  renderQuote1() {
    const { classes, data, baseUrl } = this.props;
    return (
      <div className={classes.quoteBackground}>
        <div className={classes.quoteBlock}>
          <div className={classes.imgWrapper}>
            <div className={classes.imgRound}>
              <img src={`${baseUrl}/blocks/${data.b5science.quoteImg1}`} alt="" className={classes.image} />
            </div>
            <Eclat color="red" variant="quote" className={classes.eclatQuoteTop} rotation={0}></Eclat>
          </div>
          <div className={`${classes.quoteWrapper} ${classes.red}`}>
            <Typography variant="body1" className={classes.quote}>
              {parse(data.b5science.quote1)}
            </Typography>
            <Typography variant="h5" className={classes.autor}>
              {parse(data.b5science.autor1)}
            </Typography>
            <Typography variant="h6" className={classes.role}>
              {parse(data.b5science.role1)}
            </Typography>
            <Eclat color="red" variant="quote" className={classes.eclatQuote} rotation={200}></Eclat>
          </div>
        </div>
      </div>
    );
  }

  renderQuote2() {
    const { classes, data, baseUrl } = this.props;
    return (
      <div className={`${classes.quoteBackground} ${classes.secondQuote}`}>
        <div className={classes.quoteBlock}>
          <div className={classes.imgWrapper}>
            <div className={classes.imgRound}>
              <img src={`${baseUrl}/blocks/${data.b5science.quoteImg2}`} alt="" className={classes.image} />
            </div>
            <Eclat
              color="red"
              variant="quote"
              className={`${classes.eclatQuoteTop} ${classes.eclatSecondQuoteTop}`}
              rotation={0}
            ></Eclat>
          </div>
          <div className={`${classes.quoteWrapper} ${classes.red}`}>
            <Typography variant="body1" className={classes.quote}>
              {parse(data.b5science.quote2)}
            </Typography>
            <Typography variant="h5" className={classes.autor}>
              {parse(data.b5science.autor2)}
            </Typography>
            <Typography variant="h6" className={classes.role}>
              {parse(data.b5science.role2)}
            </Typography>
            <Eclat
              color="red"
              variant="quote"
              className={`${classes.eclatQuote} ${classes.eclatSecondQuote}`}
              rotation={200}
            ></Eclat>
          </div>
        </div>
      </div>
    );
  }

  renderInner() {
    const { classes, data, attClick } = this.props;

    return (
      <Container maxWidth="lg">
        <Typography variant="h5" className={classes.subtitle}>
          {parse(data.b5science.subtitle1)}
        </Typography>
        {this.renderQuote1()}
        <Typography variant="h5" className={classes.subtitle}>
          {parse(data.b5science.subtitle2)}
        </Typography>
        <Button color="red" underline className={classes.button} onClick={this.onOpenRisks}>
          <Typography variant="button">{parse(data.b5science.btn2)}</Typography>
        </Button>
        {this.renderQuote2()}
        <Button
          color="red"
          underline
          className={classes.button}
          component="a"
          target="_blank"
          href={data.b5science.btn3Url}
          onClick={e => attClick(e, 'playlist')}
        >
          <Typography variant="button">{parse(data.b5science.btn3)}</Typography>
        </Button>
        <div className={classes.centeredButton}>
          <Button color="red" className={classes.button} onClick={PdfLoader(data.b5science.pdf, 'science')}>
            <Typography variant="button">{parse(data.b5science.btn4)}</Typography>
          </Button>
        </div>
      </Container>
    );
  }

  render() {
    const { classes, data, attClick } = this.props;
    const { openRisks } = this.state;
    return (
      <div className={classes.blockContainer}>
        <Anchor id="B5Science" />
        <Title color="red" title={parse(data.b5science.title)}></Title>
        <Risks attClick={attClick} open={openRisks} onClose={this.onCloseRisks} data={data} />
        {this.renderInner()}
      </div>
    );
  }
}

B5Science.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object,
  baseUrl: PropTypes.string,
  attClick: PropTypes.func,
};

export default withStyles(styles)(B5Science);
