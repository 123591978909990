export default theme => ({
  TypoFramedChild: {
    display: 'inline-block',
    marginBottom: 1,
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px ${theme.spacing(1)}px ${theme.spacing(1)}px`,
    textTransform: 'initial',
    fontWeight: 'initial',
    lineHeight: 0.8,
    '&.no-space': {
      marginBottom: '0',
    },
    '&.no-lateral-space': {
      paddingLeft: '0',
      paddingRight: '0',
    },
  },
  'TypoFramedChild-white': {
    color: theme.palette.blue.main,
    backgroundColor: theme.palette.white.main,
  },
  'TypoFramedChild-black': {
    color: theme.palette.white.main,
    backgroundColor: theme.palette.black.main,
  },
  'TypoFramedChild-blue': {
    color: theme.palette.white.main,
    backgroundColor: theme.palette.blue.main,
  },
  'TypoFramedChild-orange': {
    color: theme.palette.white.main,
    backgroundColor: theme.palette.orange.main,
  },
  'TypoFramedChild-yellow': {
    color: theme.palette.blue.main,
    backgroundColor: theme.palette.yellow.main,
  },
  'TypoFramedChild-red': {
    color: theme.palette.white.main,
    backgroundColor: theme.palette.red.main,
  },
  'TypoFramedChild-green': {
    color: theme.palette.white.main,
    backgroundColor: theme.palette.green.main,
  },
});
