import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Twitter from '@material-ui/icons/Twitter';
import LinkedIn from '@material-ui/icons/LinkedIn';
import YouTube from '@material-ui/icons/YouTube';
import Facebook from '@material-ui/icons/Facebook';

import Grid from '../Grid';
import styles from './styles';
import Button from '../Button';
import PdfLoader from '../../utils/PdfLoader';
import parse from '../../utils/Parser';

class Header extends React.PureComponent {
  render() {
    const { classes, baseUrl, data, attClick } = this.props;
    return (
      <div className={classes.footer}>
        <Container className={classes.container}>
          <Grid alignItems="center" justify="space-between">
            <img
              key="logoRepFr"
              className={classes.logoRepFr}
              src={`${baseUrl}/images/logo_rep_fr.svg`}
              alt="Logo République Française"
            />
            <a key="logoAnses" href={data.global.siteAnses} target="_blank" onClick={e => attClick(e, 'anses', false)}>
              <img
                key="logoAnses"
                className={classes.logoAnses}
                src={`${baseUrl}/images/logo_color.svg`}
                alt="Logo Anses"
              />
            </a>
            <div key="social" className={classes.social}>
              <IconButton
                onClick={e => attClick(e, 'twitter')}
                color="inherit"
                component="a"
                target="_blank"
                href={data.global.twitter}
              >
                <Twitter />
              </IconButton>
              <IconButton
                onClick={e => attClick(e, 'linkedin')}
                color="inherit"
                component="a"
                target="_blank"
                href={data.global.linkedin}
              >
                <LinkedIn />
              </IconButton>
              <IconButton
                onClick={e => attClick(e, 'youtube')}
                color="inherit"
                component="a"
                target="_blank"
                href={data.global.youtube}
              >
                <YouTube />
              </IconButton>
              <IconButton
                onClick={e => attClick(e, 'facebook')}
                color="inherit"
                component="a"
                target="_blank"
                href={data.global.facebook}
              >
                <Facebook />
              </IconButton>
            </div>
            <div key="buttons" className={classes.buttons}>
              <Button
                onClick={PdfLoader(data.global.reportPdf, 'reportPdf')}
                color="orange"
                size="small"
                inverse
                className={classes.btnDownload}
                noBorder
              >
                <Typography variant="overline">{parse(data.footer.reportLabel)}</Typography>
              </Button>
              <Button
                onClick={PdfLoader(data.global.brochurePdf, 'brochurePdf')}
                color="blue"
                size="small"
                inverse
                className={classes.btnDownload}
                noBorder
              >
                <Typography variant="overline">{parse(data.footer.brochureLabel)}</Typography>
              </Button>
              <Button
                onClick={PdfLoader(data.global.deontologiePdf, 'deontologiePdf')}
                color="green"
                size="small"
                inverse
                className={classes.btnDownload}
                noBorder
              >
                <Typography variant="overline">{parse(data.footer.deontologieLabel)}</Typography>
              </Button>
              <Button
                onClick={PdfLoader(data.global.productsPdf, 'productsPdf')}
                color="red"
                size="small"
                inverse
                className={classes.btnDownload}
                noBorder
              >
                <Typography variant="overline">{parse(data.footer.productsLabel)}</Typography>
              </Button>
            </div>
          </Grid>
          <Typography className={classes.credits} variant="caption">
            {parse(data.footer.credits)}
          </Typography>
        </Container>
      </div>
    );
  }
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object,
  baseUrl: PropTypes.string,
  attClick: PropTypes.func,
};

export default withStyles(styles)(Header);
