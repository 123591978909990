/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Eclat from '../../components/Eclat';
import Button from '../../components/Button';
import AnimNumber from '../../components/AnimNumber';
import DeviceInfos from '../../utils/DeviceInfos';
import styles from './styles';

import Interview from '../../popups/Interview';
import parse from '../../utils/Parser';

class B0Intro extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openInterview: false,
    };
    this.onCloseInterview = this.onCloseInterview.bind(this);
    this.onOpenInterview = this.onOpenInterview.bind(this);
  }

  onCloseInterview() {
    this.setState({ openInterview: false });
  }

  onOpenInterview(e) {
    this.props.attClick(e, 'popupInterview', 'action');
    this.setState({ openInterview: true });
  }

  renderIntro() {
    if (DeviceInfos.device.type === 'desktop') {
      return this.renderVideo();
    }
    return this.renderImage();
  }

  renderVideo() {
    const { classes, data, baseUrl } = this.props;
    // eslint-disable-next-line operator-linebreak
    const videoUrl =
      data.b0intro.video.indexOf('http') !== -1 ? data.b0intro.video : `${baseUrl}/videos/${data.b0intro.video}`;
    return (
      <Container maxWidth="lg" className={classes.container}>
        <video key={data.b0intro.video} autoPlay width="100%" muted className={classes.video}>
          <source src={videoUrl} type="video/mp4"></source>
          <Typography variant="overline">{parse(data.b0intro.videoError)}</Typography>
        </video>
      </Container>
    );
  }

  renderImage() {
    const { classes, data, baseUrl } = this.props;
    // eslint-disable-next-line operator-linebreak
    return (
      <Container maxWidth="lg" className={classes.container}>
        <img src={`${baseUrl}/blocks/${data.b0intro.videoScreenshot}`} alt="" className={classes.imageIntro} />
      </Container>
    );
  }

  renderQuote() {
    const { classes, data, baseUrl } = this.props;
    return (
      <div className={classes.quoteBackground}>
        <Container maxWidth="lg">
          <div className={classes.quoteBlock}>
            <div className={classes.imgWrapper}>
              <div className={classes.imgRound}>
                <img src={`${baseUrl}/blocks/${data.b0intro.quoteImg}`} alt="" className={classes.image} />
              </div>
              <Eclat color="blue" variant="quote" className={classes.eclatQuoteImg} rotation={0}></Eclat>
            </div>
            <div className={`${classes.quoteWrapper} ${classes.blue}`}>
              <Typography variant="subtitle2" className={classes.quote}>
                {parse(data.b0intro.quote)}
              </Typography>
              <Typography variant="h5" className={classes.autor}>
                {parse(data.b0intro.autor)}
              </Typography>
              <Typography variant="h6" className={classes.role}>
                {parse(data.b0intro.role)}
              </Typography>
              <Button size="small" color="blue" noIcon inverse onClick={this.onOpenInterview}>
                <Typography variant="overline">{parse(data.b0intro.itvBtn)}</Typography>
              </Button>
              <Eclat color="blue" variant="quote" className={classes.eclatQuote} rotation={200}></Eclat>
            </div>
          </div>
        </Container>
      </div>
    );
  }

  renderNumbers() {
    const { classes, data } = this.props;
    return (
      <div className={classes.numbersBackground}>
        <Container maxWidth="lg">
          <Typography variant="h2" className={`${classes.numbersTitle} ${classes.blue}`}>
            {parse(data.b0intro.numbersTitle)}
          </Typography>
          <div className={classes.row}>
            <div className={classes.number}>
              <AnimNumber color="yellow" value={data.b0intro.number1}></AnimNumber>
              <Typography variant="subtitle1">{parse(data.b0intro.number1type)}</Typography>
            </div>
            <div className={classes.number}>
              <AnimNumber
                color="red"
                suffix={parse(data.b0intro.number3suffix)}
                value={data.b0intro.number3}
              ></AnimNumber>
              <Typography variant="subtitle1">{parse(data.b0intro.number3type)}</Typography>
            </div>
            <div className={classes.number}>
              <AnimNumber
                color="green"
                suffix={parse(data.b0intro.number2suffix)}
                value={data.b0intro.number2}
              ></AnimNumber>
              <Typography variant="subtitle1">{parse(data.b0intro.number2type)}</Typography>
            </div>
          </div>
          <div className={classes.row}>
            <div className={classes.number}>
              <Typography variant="subtitle1">{parse(data.b0intro.number4type)}</Typography>
              <AnimNumber
                color="yellow"
                decimals={1}
                suffix={parse(data.b0intro.number4suffix)}
                value={data.b0intro.number4}
              ></AnimNumber>
            </div>
          </div>
          <div className={classes.row}>
            <div className={classes.number}>
              <AnimNumber color="red" value={data.b0intro.number5}></AnimNumber>
              <Typography variant="subtitle1">{parse(data.b0intro.number5type)}</Typography>
            </div>
            <div className={classes.number}>
              <AnimNumber color="green" value={data.b0intro.number6}></AnimNumber>
              <Typography variant="subtitle1">{parse(data.b0intro.number6type)}</Typography>
            </div>
            <div className={classes.number}>
              <AnimNumber color="yellow" value={data.b0intro.number7}></AnimNumber>
              <Typography variant="subtitle1">{parse(data.b0intro.number7type)}</Typography>
            </div>
          </div>
          <Typography variant="body1" className={classes.numbersSubtitle}>
            {parse(data.b0intro.numbersSubtitle)}
          </Typography>
          <div className={classes.row}>
            <div className={classes.number}>
              <AnimNumber color="orange" value={data.b0intro.number8}></AnimNumber>
              <Typography variant="subtitle1">{parse(data.b0intro.number8type)}</Typography>
            </div>
            <div className={classes.number}>
              <AnimNumber color="orange" value={data.b0intro.number9}></AnimNumber>
              <Typography variant="subtitle1">{parse(data.b0intro.number9type)}</Typography>
            </div>
            <div className={classes.number}>
              <AnimNumber color="orange" value={data.b0intro.number10}></AnimNumber>
              <Typography variant="subtitle1">{parse(data.b0intro.number10type)}</Typography>
            </div>
          </div>
          <Eclat color="white" variant="double" className={classes.eclatNumbersTop} rotation={200}></Eclat>
          <Eclat color="white" variant="double" className={classes.eclatNumbersBottom} rotation={0}></Eclat>
        </Container>
      </div>
    );
  }

  render() {
    const { classes, data, attClick } = this.props;
    const { openInterview } = this.state;
    return (
      <div className={classes.blockContainer}>
        {this.renderIntro()}
        {this.renderQuote()}
        {this.renderNumbers()}
        <Interview attClick={attClick} open={openInterview} onClose={this.onCloseInterview} data={data} />
      </div>
    );
  }
}

B0Intro.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object,
  baseUrl: PropTypes.string,
  attClick: PropTypes.func,
};

export default withStyles(styles)(B0Intro);
