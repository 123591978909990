import React from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Button from '../../components/Button';
import Title from '../../components/Title';
import Card from '../../components/Card';
import Eclat from '../../components/Eclat';
import Anchor from '../../components/Anchor';

import Eclairage from '../../popups/Eclairage';
import Covid from '../../popups/Covid';
import parse from '../../utils/Parser';
import styles from './styles';

class B1Covid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openEclairage: false,
      openCovid: false,
    };
    this.onCloseEclairage = this.onCloseEclairage.bind(this);
    this.onOpenEclairage = this.onOpenEclairage.bind(this);
    this.onCloseCovid = this.onCloseCovid.bind(this);
    this.onOpenCovid = this.onOpenCovid.bind(this);
  }

  onCloseEclairage() {
    this.setState({ openEclairage: false });
  }

  onOpenEclairage(e) {
    this.props.attClick(e, 'popupEclairage', 'action');
    this.setState({ openEclairage: true });
  }

  onCloseCovid() {
    this.setState({ openCovid: false });
  }

  onOpenCovid(e) {
    this.props.attClick(e, 'popupCovid', 'action');
    this.setState({ openCovid: true });
  }

  renderContent() {
    const { classes, data } = this.props;

    return (
      <Container maxWidth="lg" className={classes.container}>
        <Button underline color="blue" className={classes.button} onClick={this.onOpenCovid}>
          <Typography variant="button">{parse(data.b1covid.btn)}</Typography>
        </Button>
        <Card color="blue" imgSrc={parse(data.b1covid.cardImg)} rectangle imgLeft className={classes.card}>
          <Eclat color="white" variant="quote" className={classes.eclatTop} rotation={0}></Eclat>
          <Typography variant="h4">{parse(data.b1covid.cardDesc)}</Typography>
          <Eclat color="white" variant="quote" className={classes.eclatBottom} rotation={200}></Eclat>
          <Button underline color="white" className={classes.buttonAdd} onClick={this.onOpenEclairage}>
            <Typography variant="button">{parse(data.b1covid.btnCard)}</Typography>
          </Button>
        </Card>
      </Container>
    );
  }

  render() {
    const { classes, data, attClick } = this.props;
    const { openEclairage, openCovid } = this.state;
    return (
      <div className={classes.blockContainer}>
        <Anchor id="B1Covid" />
        <Title color="blue" title={parse(data.b1covid.title)} style={{ marginTop: 0 }}></Title>
        {this.renderContent()}
        <Eclairage attClick={attClick} open={openEclairage} onClose={this.onCloseEclairage} data={data} />
        <Covid attClick={attClick} open={openCovid} onClose={this.onCloseCovid} data={data} />
      </div>
    );
  }
}

B1Covid.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object,
  attClick: PropTypes.func,
};

export default withStyles(styles)(B1Covid);
