import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

import { ReactComponent as Arrow } from '../../res/arrowDiagonal.svg';
import parse from '../../utils/Parser';

import styles from './styles';

class Menu extends React.PureComponent {
  render() {
    const { classes, data, open, onClose } = this.props;
    return (
      <Dialog fullScreen open={open} onClose={onClose} PaperProps={{ square: true, className: classes.paper }}>
        <Container maxWidth="lg" className={classes.container}>
          <IconButton onClick={onClose} className={classes.closeIcon}>
            <CloseIcon fontSize="large" />
          </IconButton>
          <div className={classes.grid} spacing={1}>
            {data.menu.items.map(item => this.renderItem(item))}
          </div>
        </Container>
      </Dialog>
    );
  }

  renderItem(item) {
    const { classes, onClose } = this.props;
    return (
      <div key={item.num} className={`${classes.itemContainer} ${classes[`itemColor-${item.color}`]}`}>
        <ButtonBase
          key={item.num}
          className={classes.itemButton}
          onClick={onClose}
          component="a"
          href={`#${item.anchor}`}
        >
          <div className={`${classes.itemNumber} number`}>{item.num}</div>
          <div className={classes.itemContent}>
            <Arrow className={`arrow ${classes.itemArrow}`} />
            <Typography variant="h5" className={classes.itemTitle}>
              {parse(item.title)}
            </Typography>
          </div>
        </ButtonBase>
      </div>
    );
  }
}

Menu.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default withStyles(styles)(Menu);
