export default theme => ({
  blockContainer: {
    position: 'relative',
  },
  container: {
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  button: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  card: {
    position: 'relative',
  },
  eclatTop: {
    position: 'relative',
    top: -10,
    right: 60,
    [theme.breakpoints.down('sm')]: {
      width: 50,
      right: 'initial',
      left: 0,
    },
  },
  eclatBottom: {
    position: 'relative',
    top: 10,
    left: '80%',
    [theme.breakpoints.down('sm')]: {
      width: 50,
    },
  },
  buttonAdd: {
    paddingLeft: 0,
    color: theme.palette.white.main,
    '& > svg': {
      backgroundColor: theme.palette.white.main,
      fill: theme.palette.blue.main,
      color: theme.palette.blue.main,
      stroke: theme.palette.blue.main,
    },
    // borderRadius: '50%',
    // backgroundColor: theme.palette.secondary.main,
    // boxShadow: 'none',
    // transition: 'all 0.3s',
    // '& svg': {
    //   transform: 'scale(0.65)',
    // },
    // '&:hover': {
    //   backgroundColor: theme.palette.secondary.main,
    //   transform: 'rotate(45deg)',
    // },
    // [theme.breakpoints.down('xs')]: {
    //   width: 40,
    //   height: 40,
    // },
  },
  add: {
    fill: theme.palette.blue.main,
    stroke: theme.palette.blue.main,
  },
});
