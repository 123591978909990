export default theme => ({
  appBar: {
    backgroundColor: theme.palette.orange.main,
  },
  container: {
    padding: 0,
  },
  leftItem: {
    paddingTop: `${theme.spacing(0)} !important`,
    paddingBottom: `${theme.spacing(0)} !important`,
  },
  logoLink: {
    display: 'inline-block',
    height: '100%',
  },
  logo: {
    height: '100%',
  },
  rightItem: {},
  gridRight: {
    margin: 0,
  },
  btnDownload: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  socialContainer: {
    marginRight: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  buttonLang: {
    ...theme.typography.h6,
    fontWeight: 100,
    color: theme.palette.white.main,
  },
  langActive: {
    fontWeight: 500,
  },
});
