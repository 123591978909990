export default theme => ({
  paper: {
    backgroundColor: theme.palette.blue.main,
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(1),
      width: 'calc(100% - 16px)',
    },
  },
  container: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(7),
    color: theme.palette.white.main,
    fill: theme.palette.white.main,
  },
  closeIcon: {
    position: 'absolute',
    top: theme.spacing(2),
    left: theme.spacing(2),
    color: theme.palette.white.main,
  },
  inner: {
    width: 'calc(100% - 150px)',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  eclat: {
    position: 'relative',
    width: 130,
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      width: 80,
    },
  },
  arrow: {
    width: 20,
  },
  author: {
    marginBottom: theme.spacing(1),
  },
  role: {
    marginBottom: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(7),
  },
  title: {
    position: 'absolute',
    right: theme.spacing(7),
    top: -50,
    fontSize: '9rem',
    fontWeight: 700,
    transformOrigin: '100% 100%',
    transform: 'rotate(-90deg)',
    lineHeight: 1,
    [theme.breakpoints.down('sm')]: {
      top: 50,
      fontSize: '8rem',
      right: theme.spacing(4),
    },
    [theme.breakpoints.down('xs')]: {
      top: 50,
      transform: 'initial',
      fontSize: '2rem',
      right: theme.spacing(1),
    },
  },
});
