import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';
import { ReactComponent as Play } from '../../res/play.svg';

import Grid from '../Grid';
import PopupInterview from '../PopupInterview';
import styles from './styles';

class Card extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      openInterview: false,
    };
    this.onOpenInterview = this.onOpenInterview.bind(this);
    this.onCloseInterview = this.onCloseInterview.bind(this);
  }

  onOpenInterview() {
    this.setState({ openInterview: true });
  }

  onCloseInterview() {
    this.setState({ openInterview: false });
  }

  renderPopupInterview() {
    const { videoUrl } = this.props;
    const { openInterview } = this.state;
    return <PopupInterview videoUrl={videoUrl} open={openInterview} onClose={this.onCloseInterview} />;
  }

  renderInner() {
    const { children, color, classes, imgLeft } = this.props;
    const colorClass = classes[color] || classes.black;
    const direction = imgLeft ? 'row' : 'row-reverse';
    return (
      <Grid
        spacing={0}
        sm={12}
        md={6}
        className={`${classes.grid} ${colorClass}`}
        alignItems="stretch"
        direction={direction}
      >
        <Grid.Item key="img" sm={12} md={6} className={classes.gridItem}>
          {this.renderImage()}
        </Grid.Item>
        <Grid key="content" spacing={0} alignItems="center" className={classes.textContent}>
          <div key="children">{children}</div>
        </Grid>
      </Grid>
    );
  }

  renderImage() {
    if (this.props.videoUrl) {
      return this.renderButtonImage();
    }
    return this.renderDefaultImage();
  }

  renderDefaultImage() {
    const { imgSrc, classes } = this.props;
    return (
      <div key="img" className={classes.imageContainer} style={{ backgroundImage: `url(${imgSrc})` }}>
        {this.renderImageTitle()}
        {this.renderImageSubtitle()}
      </div>
    );
  }

  renderButtonImage() {
    const { imgSrc, classes, whiteImageTitle } = this.props;
    return (
      <ButtonBase
        key="img"
        className={`btnImg ${classes.imageContainer} ${classes.buttonVideo}`}
        style={{ backgroundImage: `url(${imgSrc})` }}
        onClick={this.onOpenInterview}
      >
        <Play className={`playIcon ${classes.playIcon} ${whiteImageTitle ? classes.iconWhiteImageTitle : ''}`} />
        {this.renderImageTitle()}
        {this.renderImageSubtitle()}
      </ButtonBase>
    );
  }

  renderImageTitle() {
    const { classes, imageTitle, whiteImageTitle } = this.props;
    if (!imageTitle) {
      return null;
    }
    return (
      <Typography
        variant="button"
        className={`${classes.imageTitle} ${whiteImageTitle ? classes.whiteImageTitle : ''}`}
      >
        {imageTitle}
      </Typography>
    );
  }

  renderImageSubtitle() {
    const { classes, imageSubtitle } = this.props;
    if (!imageSubtitle) {
      return null;
    }
    return (
      <Typography variant="caption" className={classes.imageSubtitle}>
        {imageSubtitle}
      </Typography>
    );
  }

  renderSquare() {
    const { classes, color } = this.props;
    const colorClass = classes[color] || classes.black;

    return <div className={`${classes.rectangle} ${colorClass}`}></div>;
  }

  getPaperProps() {
    const { classes, PaperProps } = this.props;
    return {
      elevation: 0,
      square: true,
      className: classes.container,
      ...PaperProps,
    };
  }

  render() {
    const { rectangle, className, classes, videoUrl } = this.props;
    return (
      <Paper {...this.getPaperProps()} className={`${className || ''} ${classes.card}`}>
        {this.renderInner()}
        {rectangle ? this.renderSquare() : ''}
        {videoUrl ? this.renderPopupInterview() : null}
      </Paper>
    );
  }
}

Card.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  children: PropTypes.any.isRequired,
  color: PropTypes.string,
  imgSrc: PropTypes.string.isRequired,
  imgLeft: PropTypes.bool,
  rectangle: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  PaperProps: PropTypes.object,
  videoUrl: PropTypes.string,
  imageTitle: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  imageSubtitle: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  whiteImageTitle: PropTypes.bool,
};

Card.defaultProps = {
  color: 'black',
  imgLeft: false,
  rectangle: false,
};

export default withStyles(styles)(Card);
