export default theme => ({
  blockContainer: {
    position: 'relative',
  },
  container: {
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  card: {
    margin: theme.spacing(8, 0, 8, 0),
  },
  // subtitle: {
  //   textTransform: 'uppercase',
  //   fontWeight: 'bold',
  //   padding: theme.spacing(2, 0, 2, 0),
  //   marginTop: theme.spacing(8),
  //   marginBottom: theme.spacing(4),
  //   [theme.breakpoints.down('xs')]: {
  //     padding: theme.spacing(2),
  //   },
  // },
  subtitle: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    padding: theme.spacing(2, 0, 2, 0),
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
    },
  },
  centeredButton: {
    textAlign: 'center',
  },
  text: {
    '& em': {
      fontStyle: 'normal',
      fontSize: '2em',
    },
  },
  row: {
    display: 'flex',
    justifyContent: 'center',
    margin: theme.spacing(0, -4, 0, -4),
    paddingTop: theme.spacing(8),
    '& > *': {
      width: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(4),
      margin: theme.spacing(0, -2, 0, -2),
      flexWrap: 'wrap',
      '& > *': {
        width: 'auto',
      },
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(4),
      margin: 0,
      flexWrap: 'wrap',
      '& > *': {
        width: 'auto',
      },
    },
  },
  number: {
    position: 'relative',
    zIndex: 2,
    display: 'inline-block',
    textAlign: 'center',
    margin: theme.spacing(0, 4, 0, 4),
    paddingBottom: theme.spacing(2),
    borderBottom: `solid 5px ${theme.palette.yellow.main}`,
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0, 2, 1, 2),
      paddingBottom: theme.spacing(1),
    },
  },
  numbersTitle: {
    position: 'relative',
    zIndex: 2,
    textAlign: 'center',
    textDecoration: 'underline',
    textTransform: 'uppercase',
    marginTop: theme.spacing(4),
  },
  numbersBlock: {
    marginBottom: theme.spacing(8),
  },
  longNumber: {
    marginTop: 22,
  },
});
