/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import PropTypes from 'prop-types';
import Container from '@material-ui/core/Container';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Eclat from '../../components/Eclat';
import Card from '../../components/Card';
import Card2 from '../../components/Card2';
import Button from '../../components/Button';
import Title from '../../components/Title';
import Anchor from '../../components/Anchor';
import PdfLoader from '../../utils/PdfLoader';
import parse from '../../utils/Parser';
import styles from './styles';

class B3Exposures extends React.Component {
  renderQuote() {
    const { classes, data, baseUrl } = this.props;
    return (
      <div className={classes.quoteBackground}>
        <div className={classes.quoteBlock}>
          <div className={classes.imgWrapper}>
            <div className={classes.imgRound}>
              <img src={`${baseUrl}/blocks/${data.b3exposures.quoteImg}`} alt="" className={classes.image} />
            </div>
            <Eclat color="green" variant="quote" className={classes.eclatQuoteTop} rotation={0}></Eclat>
          </div>
          <div className={classes.quoteWrapper}>
            <Typography variant="body1" className={classes.quote}>
              {parse(data.b3exposures.quote)}
            </Typography>
            <Typography variant="h5" className={classes.autor}>
              {parse(data.b3exposures.autor)}
            </Typography>
            <Typography variant="h6" className={classes.role}>
              {parse(data.b3exposures.role)}
            </Typography>
            <Eclat color="green" variant="quote" className={classes.eclatQuote} rotation={200}></Eclat>
          </div>
        </div>
      </div>
    );
  }

  renderInner() {
    const { classes, data } = this.props;

    return (
      <Container maxWidth="lg" className={classes.container}>
        <Typography variant="h5" className={classes.subtitle}>
          {parse(data.b3exposures.subtitle1)}
        </Typography>
        <Card2
          rectangle
          color="green"
          imgSrc={parse(data.b3exposures.card1Img)}
          additionalText={parse(data.b3exposures.card1Desc2)}
          imgLeft
          className={classes.card}
        >
          <Typography variant="h4">{parse(data.b3exposures.card1DescTitle)}</Typography>
          <Typography variant="body1" className={classes.text}>
            {parse(data.b3exposures.card1Desc)}
          </Typography>
        </Card2>
        <Typography variant="h5" className={classes.subtitle}>
          {parse(data.b3exposures.subtitle2)}
        </Typography>
        {this.renderQuote()}
        <Card color="green" imgSrc={parse(data.b3exposures.card2Img)} imgLeft className={classes.card} rectangle>
          <Typography variant="body1">{parse(data.b3exposures.card2Desc)}</Typography>
        </Card>
        <div className={classes.centeredButton}>
          <Button color="green" className={classes.button} onClick={PdfLoader(data.b3exposures.pdf, 'exposures')}>
            <Typography variant="button">{parse(data.b3exposures.btn)}</Typography>
          </Button>
        </div>
      </Container>
    );
  }

  render() {
    const { classes, data } = this.props;
    return (
      <div className={classes.blockContainer}>
        <Anchor id="B3Exposures" />
        <Title color="green" title={parse(data.b3exposures.title)}></Title>
        {this.renderInner()}
      </div>
    );
  }
}

B3Exposures.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object,
  baseUrl: PropTypes.string,
  // attClick: PropTypes.func,
};

export default withStyles(styles)(B3Exposures);
