import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import styles from './styles';

class TypoFramed extends React.PureComponent {
  getEnrichedChildren(children) {
    const { classes, frame, variant, space } = this.props;
    const frameClass = classes[`TypoFramedChild-${frame}`];
    const noSpace = space ? '' : 'no-space';
    if (!Array.isArray(children)) {
      return <span className={`${classes.TypoFramedChild} ${variant} ${frameClass}`}>{children}</span>;
    }

    const nodes = [];
    let current = [];
    children.forEach((child, idx) => {
      if ((child.type === 'br' && idx > 0) || idx === children.length - 1) {
        if (idx === children.length - 1) {
          current.push(child);
        }
        nodes.push(
          <span key={nodes.length} className={`${classes.TypoFramedChild} ${variant} ${frameClass} ${noSpace} `}>
            {current}
          </span>,
        );
        current = [];
        if (child.type === 'br') {
          nodes.push(<React.Fragment key={nodes.length}>{child}</React.Fragment>);
        }
      } else {
        current.push(child);
      }
    });
    return nodes;
  }

  render() {
    const { frame, classes, children, space, ...typoProps } = this.props;
    const enrichedChildren = this.getEnrichedChildren(children);
    return <Typography {...typoProps}>{enrichedChildren}</Typography>;
  }
}

TypoFramed.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node,
  frame: PropTypes.oneOf(['black', 'white', 'blue', 'orange', 'red', 'green', 'yellow']),
  variant: PropTypes.string,
  space: PropTypes.bool,
};

TypoFramed.defaultProps = {
  frame: 'white',
  variant: 'body1',
  space: true,
};

export default withStyles(styles)(TypoFramed);
