export default theme => ({
  paper: {
    backgroundColor: theme.palette.blue.main,
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(1),
      width: 'calc(100% - 16px)',
    },
  },
  container: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(7),
    color: theme.palette.white.main,
    fill: theme.palette.white.main,
  },
  closeIcon: {
    position: 'absolute',
    top: theme.spacing(2),
    left: theme.spacing(2),
    color: theme.palette.white.main,
  },
  title: {
    marginBottom: theme.spacing(4),
  },
  mainArrow: {
    paddingLeft: theme.spacing(2),
    width: 50,
    [theme.breakpoints.down('xs')]: {
      width: 30,
    },
  },
  subtitle: {
    marginBottom: theme.spacing(8),
  },
  layout: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  columnLeft: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  columnRight: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  part: {
    position: 'relative',
    marginBottom: theme.spacing(4),
    '& .h4': {
      textTransform: 'uppercase',
      fontWeight: 700,
    },
  },
  partTitle: {
    position: 'relative',
    zIndex: 2,
  },
  partText: {
    position: 'relative',
    zIndex: 2,
    maxWidth: 450,
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    '& u': {
      fontWeight: 700,
      textTransform: 'uppercase',
    },
    '& i': {
      fontWeight: 700,
      color: theme.palette.orange.main,
      fontStyle: 'initial',
    },
  },
  partOrange: {
    borderLeft: `solid 2px ${theme.palette.orange.main}`,
  },
  partRed: {
    borderLeft: `solid 2px ${theme.palette.red.main}`,
  },
  partYellow: {
    borderLeft: `solid 2px ${theme.palette.yellow.main}`,
  },
  partGreen: {
    borderLeft: `solid 2px ${theme.palette.green.main}`,
  },
  partWhite: {
    borderLeft: `solid 2px ${theme.palette.white.main}`,
  },
  eclatOrange: {
    position: 'relative',
    zIndex: 1,
    width: 150,
    left: '5%',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  eclatYellow: {
    position: 'relative',
    zIndex: 1,
    width: 100,
    left: '80%',
    margin: theme.spacing(6, 0, 12, 0),
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  eclatGreen: {
    position: 'relative',
    zIndex: 1,
    width: 150,
    left: '15%',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  eclatRed: {
    position: 'relative',
    zIndex: 1,
    width: 100,
    left: '50%',
    bottom: 50,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  eclatOrangeMobile: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      position: 'relative',
      zIndex: 1,
      width: 100,
      left: '50%',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      position: 'relative',
      zIndex: 1,
      width: 90,
      left: 'calc(80% - 90px)',
    },
  },
  eclatGreenMobile: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      position: 'relative',
      zIndex: 1,
      width: 150,
      left: 'calc(80% - 150px)',
    },
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      position: 'relative',
      zIndex: 1,
      width: 110,
      bottom: 20,
      left: 'calc(90% - 120px)',
    },
  },
  eclatYellowMobile: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      position: 'relative',
      zIndex: 1,
      width: 150,
      left: '25%',
      [theme.breakpoints.down('xs')]: {
        display: 'block',
        position: 'relative',
        zIndex: 1,
        width: 110,
        left: '35%',
      },
    },
  },
  eclatRedMobile: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      position: 'relative',
      zIndex: 1,
      width: 100,
      left: '60%',
      bottom: 50,
    },
    [theme.breakpoints.down('xs')]: {
      display: 'block',
      position: 'relative',
      zIndex: 1,
      width: 80,
      left: '70%',
      bottom: 50,
    },
  },
  centeredButton: {
    textAlign: 'center',
    marginTop: theme.spacing(8),
  },
  button: {
    transition: 'background-color 0.3s',
    backgroundColor: theme.palette.red.main,
    '&:hover': {
      backgroundColor: theme.palette.white.main,
    },
  },
});
