export default theme => ({
  blockContainer: {
    position: 'relative',
  },
  container: {
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  card: {
    margin: theme.spacing(8, 0, 8, 0),
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(2, 0, 2, 0),
    },
  },
  subtitle: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    padding: theme.spacing(2, 0, 2, 0),
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
    },
  },
  quoteBackground: {
    backgroundColor: theme.palette.secondary.main,
  },
  quoteBlock: {
    marginBottom: theme.spacing(8),
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  eclatQuoteTop: {
    position: 'absolute',
    width: 150,
    top: -theme.spacing(1),
    right: 0,
    [theme.breakpoints.down('sm')]: {
      width: 75,
      top: 'initial',
      right: 'initial',
      bottom: -theme.spacing(2),
      left: theme.spacing(2),
    },
  },
  eclatQuote: {
    position: 'absolute',
    bottom: theme.spacing(2),
    width: 110,
    right: 'calc(40% - 110px)',
    [theme.breakpoints.down('sm')]: {
      width: 60,
      bottom: theme.spacing(-4),
    },
  },
  imgWrapper: {
    position: 'relative',
    flex: 1,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(2),
    },
  },
  imgRound: {
    position: 'relative',
    width: 400,
    height: 400,
    borderRadius: 500,
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      width: 250,
      height: 250,
    },
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center bottom',
  },
  quoteWrapper: {
    flex: '2 1 0',
    position: 'relative',
    padding: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
    },
  },
  quote: {
    paddingBottom: theme.spacing(4),
  },
  autor: {
    paddingBottom: theme.spacing(1),
    color: theme.palette.green.main,
  },
  role: {
    paddingBottom: theme.spacing(2),
    color: theme.palette.green.main,
  },
  centeredButton: {
    textAlign: 'center',
  },
  text: {
    '& em': {
      fontStyle: 'normal',
      fontSize: '2em',
      [theme.breakpoints.down('xs')]: {
        fontSize: '1.4em',
      },
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(2),
    },
  },
});
