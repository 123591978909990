import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

import { ReactComponent as Arrow } from '../../res/arrowDiagonal.svg';
import Eclat from '../../components/Eclat';
import TypoFramed from '../../components/TypoFramed';
import parse from '../../utils/Parser';

import styles from './styles';

class Covid extends React.PureComponent {
  render() {
    const { classes, open, onClose } = this.props;
    return (
      <Dialog
        fullWidth
        maxWidth="lg"
        open={open}
        onClose={onClose}
        PaperProps={{ square: true, className: classes.paper }}
      >
        <Container maxWidth="lg" className={classes.container}>
          <IconButton onClick={onClose} className={classes.closeIcon}>
            <CloseIcon fontSize="large" />
          </IconButton>
          {this.renderInner()}
        </Container>
      </Dialog>
    );
  }

  renderInner() {
    const { data, classes } = this.props;
    return (
      <div className={classes.inner}>
        <Typography variant="h2" className={classes.title}>
          {parse(data.covid.title)}
          <Arrow className={classes.mainArrow} />
        </Typography>
        <Typography variant="h4" className={classes.subtitle}>
          {parse(data.covid.subtitle)}
        </Typography>
        <div className={classes.layout}>
          <div className={classes.columnLeft}>
            <div className={classes.part}>
              <Eclat color="orange" rotation={200} className={classes.eclatOrange} />
              <TypoFramed variant="h4" className={classes.partTitle} space={false} frame="orange">
                {parse(data.covid.part1Title)}
              </TypoFramed>
              <Typography variant="body1" className={`${classes.partText} ${classes.partOrange}`}>
                {parse(data.covid.part1)}
              </Typography>
            </div>
            <div className={classes.part}>
              <Eclat color="yellow" rotation={-70} className={classes.eclatYellow} />
              <TypoFramed variant="h4" className={classes.partTitle} space={false} frame="yellow">
                {parse(data.covid.part3Title)}
              </TypoFramed>
              <Typography variant="body1" className={`${classes.partText} ${classes.partYellow}`}>
                {parse(data.covid.part3)}
              </Typography>
            </div>
            <div className={classes.part}>
              <Eclat color="red" rotation={-70} className={classes.eclatRed} />
              <TypoFramed variant="h4" className={classes.partTitle} space={false} frame="red">
                {parse(data.covid.part5Title)}
              </TypoFramed>
              <Typography variant="body1" className={`${classes.partText} ${classes.partRed}`}>
                {parse(data.covid.part5)}
              </Typography>
            </div>
          </div>
          <div className={classes.columnRight}>
            <div className={`${classes.part} ${classes.part2}`}>
              <TypoFramed variant="h4" className={classes.partTitle} space={false} frame="white">
                {parse(data.covid.part2Title)}
              </TypoFramed>
              <Typography variant="body1" className={`${classes.partText} ${classes.partWhite}`}>
                {parse(data.covid.part2)}
              </Typography>
            </div>
            <div className={classes.part}>
              <Eclat color="green" rotation={120} className={classes.eclatGreen} />
              <TypoFramed variant="h4" className={classes.partTitle} space={false} frame="green">
                {parse(data.covid.part4Title)}
              </TypoFramed>
              <Typography variant="body1" className={`${classes.partText} ${classes.partGreen}`}>
                {parse(data.covid.part4)}
              </Typography>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Covid.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default withStyles(styles)(Covid);
