import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import { Typography } from '@material-ui/core';
import Eclat from '../Eclat';
import styles from './styles';

class Title extends React.PureComponent {
  render() {
    const { classes, color, className, title, ...other } = this.props;

    return (
      <div {...other} className={`${classes.container} ${classes[color]} ${className || ''}`}>
        <Container maxWidth="lg">
          <div className={classes.eclatContainer}>
            <Eclat color="white" className={classes.eclatTop} rotation={-160} scaleX={1}></Eclat>
            <Eclat color="white" className={classes.eclatBottom} rotation={10} scaleX={-1} size={1.5}></Eclat>
          </div>
          <Typography variant="h2" color="secondary" className={classes.title}>
            {title}
          </Typography>
        </Container>
      </div>
    );
  }
}

Title.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf(['blue', 'orange', 'green', 'yellow', 'red', 'white', 'black']),
  className: PropTypes.string,
  title: PropTypes.any,
};

Title.defaultProps = {
  color: 'blue',
};

export default withStyles(styles)(Title);
