function attClick(e, name) {
  if (e && name) {
    window.ATTag.click.send({
      elem: e.target,
      name,
      level2: 'clickLvl2',
      type: 'download',
    });
  }
}

export default function PdfLoader(link, name) {
  if (link) {
    const BASE_URL = !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? '' : process.env.PUBLIC_URL;
    const url = `${BASE_URL}/pdf/${link}`;
    return e => {
      attClick(e, name);
      window.open(url, '_blank');
    };
  }
  return null;
}
