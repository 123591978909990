export default theme => ({
  container: {
    textAlign: 'left',
    backgroundColor: theme.palette.white.main,
    position: 'relative',
  },
  card: {
    position: 'relative',
  },
  grid: {
    position: 'relative',
  },
  gridItem: {
    width: '100%',
  },
  imageContainer: {
    backgroundPosition: 'top',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    height: '100%',
    width: '100%',
    minHeight: 400,
    [theme.breakpoints.down('xs')]: {
      minHeight: 300,
    },
  },
  buttonVideo: {
    textAlign: 'left',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    paddingBottom: theme.spacing(2),
  },
  playIcon: {
    marginLeft: theme.spacing(4),
    stroke: theme.palette.black.main,
    fill: theme.palette.black.main,
    transition: 'fill 0.3s, stroke 0.3s',
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(2),
      width: 40,
      height: 40,
    },
  },
  iconWhiteImageTitle: {
    stroke: theme.palette.white.main,
    fill: theme.palette.white.main,
  },
  textContent: {
    position: 'relative',
    zIndex: 3,
    minHeight: '100%',
    padding: theme.spacing(6),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
    },
  },
  blue: {
    backgroundColor: theme.palette.blue.main,
    color: theme.palette.blue.contrastText,
    '& .btnImg:hover .playIcon': {
      fill: theme.palette.blue.main,
      stroke: theme.palette.blue.main,
    },
  },
  orange: {
    backgroundColor: theme.palette.orange.main,
    color: theme.palette.orange.contrastText,
    '& .btnImg:hover .playIcon': {
      fill: theme.palette.orange.main,
      stroke: theme.palette.orange.main,
    },
  },
  green: {
    backgroundColor: theme.palette.green.main,
    color: theme.palette.green.contrastText,
    '& .btnImg:hover .playIcon': {
      fill: theme.palette.green.main,
      stroke: theme.palette.green.main,
    },
  },
  yellow: {
    backgroundColor: theme.palette.yellow.main,
    color: theme.palette.yellow.contrastText,
    '& .btnImg:hover .playIcon': {
      fill: theme.palette.yellow.main,
      stroke: theme.palette.yellow.main,
    },
  },
  red: {
    backgroundColor: theme.palette.red.main,
    color: theme.palette.red.contrastText,
    '& .btnImg:hover .playIcon': {
      fill: theme.palette.red.main,
      stroke: theme.palette.red.main,
    },
  },
  white: {
    backgroundColor: theme.palette.white.main,
    color: theme.palette.white.contrastText,
  },
  black: {
    backgroundColor: theme.palette.black.main,
    color: theme.palette.black.contrastText,
  },
  rectangle: {
    position: 'absolute',
    zIndex: 2,
    top: '50%',
    left: '50%',
    width: 40,
    height: 40,
    transform: 'translate(-50%, -50%) rotate(45deg)',
    [theme.breakpoints.down('sm')]: {
      top: 400,
      transform: 'translate(-50%, -50%) rotate(45deg)',
    },
    [theme.breakpoints.down('xs')]: {
      top: 300,
      transform: 'translate(-50%, -50%) rotate(45deg)',
    },
  },
  imageTitle: {
    width: '100%',
    textTransform: 'initial',
    color: theme.palette.primary.main,
    padding: theme.spacing(0, 4, 2, 4),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
    },
  },
  whiteImageTitle: {
    color: theme.palette.white.main,
  },
  imageSubtitle: {
    width: '100%',
    textTransform: 'initial',
    backgroundColor: 'rgba(255,255,255,0.6)',
    color: theme.palette.primary.main,
    padding: theme.spacing(2, 4, 2, 4),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
    },
  },
});
